import { MODULE_ICON_MAP } from "../../../constants/moduleicon";
import { ROUTE_PATHS } from "../../../routes/route-paths";

const BREADCRUMB_ICON_NAME = MODULE_ICON_MAP.AMBASSADORS;

const AMBASSADOR_BREADCRUMB = {
  [ROUTE_PATHS.AMBASSADORS.LIST]: {
    isRoot: true,
    paths: [{ label: "Ambassadors", icon: BREADCRUMB_ICON_NAME }],
    title: "Ambassadors",
  },
  [ROUTE_PATHS.AMBASSADORS.ADD]: {
    isRoot: false,
    paths: [
      {
        url: ROUTE_PATHS.AMBASSADORS.LIST,
        label: "Ambassadors",
        icon: BREADCRUMB_ICON_NAME,
      },
      { label: "Add" },
    ],
    title: "Add",
  },
  [ROUTE_PATHS.AMBASSADORS.EDIT]: {
    isRoot: false,
    paths: [
      {
        url: ROUTE_PATHS.AMBASSADORS.LIST,
        label: "Ambassadors",
        icon: BREADCRUMB_ICON_NAME,
      },
      { label: "Edit" },
    ],
    title: "Edit",
  },
  [ROUTE_PATHS.AMBASSADORS.DETAILS]: {
    isRoot: false,
    paths: [
      {
        url: ROUTE_PATHS.AMBASSADORS.LIST,
        label: "Ambassadors",
        icon: BREADCRUMB_ICON_NAME,
      },
      { label: "{name}", isCustom: true },
    ],
    title: "Details",
  },
};

export { AMBASSADOR_BREADCRUMB };
