import GridViewIcon from "@mui/icons-material/GridView";
import PeopleAltSharpIcon from "@mui/icons-material/PeopleAltSharp";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import StorefrontIcon from "@mui/icons-material/Storefront";
import RateReviewIcon from "@mui/icons-material/RateReview";
import PriceChangeIcon from '@mui/icons-material/PriceChange';

export const MODULE_ICON_MAP = {
  DASHBOARD: <GridViewIcon />,
  CLIENTS: <PeopleAltSharpIcon />,
  AMBASSADORS: <ContactPageOutlinedIcon />,
  INVITATIONS: <EventNoteOutlinedIcon />,
  CONNECT: <HowToRegIcon />,
  OPPORTUNITY: <SupervisedUserCircleIcon />,
  MARKETPLACE: <StorefrontIcon />,
  REVIEWS: <RateReviewIcon />,
  REVENUE: <PriceChangeIcon />
};
