import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Checkbox, Collapse, Grid, IconButton } from "@mui/material";
import "./../styles.css";
import Paginator from "../../pagination";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export const PaginationContext: any = React.createContext({});

function LoadingText() {
  return <div className="mui-table-loading">Loading</div>;
}

export default function SimpleTable<T>({
  columns = [],
  rows,
  total,
  onPageSettingsChange,
  tdRenderer,
  showPageSize = true,
  selectedPageSize,
  selectedPage,
  renderExpandedRowData,
  exandableRow = false,
  onRowSelect,
  selectedRows = {
    field: "id",
    values: [],
  },
  fetching,
  showPagination = true,
}: any) {
  const onPageSizeChange = (newValue) => {
    onPageSettingsChange({
      selectedPageSize: newValue,
      selectedPage: 1,
    });
  };

  const onPageChange = (newValue) => {
    onPageSettingsChange({
      selectedPageSize,
      selectedPage: newValue,
    });
  };

  function Row(props) {
    const { row, index } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow
          className="table-row"
          sx={{ "& > *": { borderBottom: "unset" } }}
        >
          {columns.map((columnItem, i) => {
            switch (columnItem["field"]) {
              case "checkbox":
                return (
                  <TableCell padding="checkbox" key={`columnItem-${i}`}>
                    <Checkbox
                      onClick={(event) =>
                        onRowSelect({ event, row, index, column: columnItem })
                      }
                      className="selectCheckbox"
                      checked={
                        selectedRows.values.indexOf(row[selectedRows.field]) !==
                        -1
                      }
                    />
                  </TableCell>
                );
                break;
              case "expandable":
                return (
                  <TableCell key={`columnItem-${i}`} className="table-cell">
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => setOpen(!open)}
                    >
                      {open ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </TableCell>
                );
              default:
                return (
                  <TableCell
                    align={columnItem.align}
                    key={`columnItem-${i}`}
                    className="table-cell"
                  >
                    {columnItem.rendererType
                      ? tdRenderer(row, columnItem, i)
                      : columnItem?.field
                          ?.split(".")
                          ?.reduce(function (p, prop) {
                            return p?.[prop];
                          }, row)}
                  </TableCell>
                );
                break;
            }
          })}
        </TableRow>
        {exandableRow && (
          <TableRow>
            <TableCell
              style={{ paddingBottom: 0, paddingTop: 0 }}
              colSpan={columns.length}
            >
              <Collapse in={open} timeout="auto" unmountOnExit>
                {renderExpandedRowData({ row, index })}
              </Collapse>
            </TableCell>
          </TableRow>
        )}
      </React.Fragment>
    );
  }

  return (
    <Grid container id="simple-table-grid" spacing={2} direction="column">
      <Grid item xs={12}>
        <TableContainer
          sx={{ maxWidth: window?.screen?.width, overflowX: "auto" }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow sx={{ "& > *": { borderBottom: "1px" } }}>
                {columns.map(({ title, align, width }, index) => (
                  <TableCell
                    sx={{ width }}
                    align={align}
                    key={`column-${index}`}
                  >
                    <span className="table-header">{title}</span>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.length ? (
                rows.map((row: T, index) => (
                  <Row index={index} row={row} key={`rowItem-${index}`} />
                ))
              ) : (
                <TableRow className="table-row">
                  <TableCell
                    colSpan={columns.length}
                    className="table-cell"
                    style={{ borderBottom: 0 }}
                  >
                    {fetching ? <LoadingText /> : "No Data Found."}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {rows?.length > 0 && showPagination && (
        <Grid item>
          <PaginationContext.Provider
            value={{
              onPageChange,
              onPageSizeChange,
              totalRecords: total,
              showPageSize,
              selectedPageSize,
              selectedPage,
            }}
          >
            <Paginator />
          </PaginationContext.Provider>
        </Grid>
      )}
    </Grid>
  );
}
