import { useEffect, useState } from "react";
import { AppTextField } from "../../../components/textfields";
import { CLIENT_DETAILS, CLIENT_FORM_ERROR_MAAPING } from "./form-data";
import { useStyles } from "./styles";
import { SaveAndCancelButton } from "../../../components/buttons";
import {
  ENUM_AUTOSELECT_LIST_TYPES,
  ENUM_FORM_INPUT_TYPES,
} from "../../../enums";
import { useHistory, useParams, Prompt } from "react-router";
import { validateForm } from "../../../services/validators";
import { useDispatch, useSelector } from "react-redux";
import {
  addClient,
  getClientDetails,
  updateClient,
} from "../../../apis/clients.api";
import { AppAlertBox } from "../../../components/modals/alert-box";
import { SelectboxWithChips } from "../../../components/shared/selectbox-with-chips";
import { setSelectedClient } from "../../../redux/modules/clients/actions";
import { Box, Container, Grid, Paper } from "@mui/material";
import { ROUTE_PATHS } from "../../../routes/route-paths";
import { AutoSelectbox } from "../../../components/selectbox/autoselect";
import { GLOBAL_TEXT } from "../../../constants";
import { EmailAddressBox } from "../../components/email-addressbox";
import { AppCheckbox } from "../../../components/checkboxes";

const initialFields: any = {
  firstName: "",
  lastName: "",
  email: "",
  title: "",
  company: "",
  no_of_emp: "",
  funding: "",
  revenues: "",
  budget: "",
  target_industries: "",
  target_no_of_emp: "",
  target_account: "",
  linkedIn: "",
  timezone: "",
  viewAmbassadorThreshold: "",
  meetingThreshold: "",
  viewNextBatchAmbassadorThreshold: "",
  licenseForMeeting: "",
};

const initialErrors = {
  ...initialFields,
};

function AddOrUpdateClient() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id }: any = useParams();
  const [formFields, setFormField] = useState(initialFields);
  const [formErrors, setFormErrors] = useState(initialErrors);
  const [blockNavigation, setBlockNavigation] = useState(true);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const [initialFieldData, setInitialFieldData] = useState(initialFields);
  const [nextLocationPath, setNextLocationPath] = useState();
  const { timezones, noOfEmp, industry, title } = useSelector(
    (state: any) => state.common
  );
  const [emailIds, setEmailIds]: any = useState({
    list: [],
    invalidEmailIds: [],
  });
  const { selectedClient } = useSelector((state: any) => state.clients);

  useEffect(() => {
    onLoad();

    return () => {
      dispatch(setSelectedClient(undefined));
    };
  }, []);

  useEffect(() => {
    showCancelConfirm && setBlockNavigation(false);
  }, [showCancelConfirm]);

  useEffect(() => {
    if (selectedClient) {
      const data = {
        ...selectedClient,
        target_industries: industry?.data
          ?.filter((item) =>
            selectedClient.target_industries.includes(item.label)
          )
          ?.map((item) => item.label),
        target_no_of_emp: noOfEmp?.data
          ?.filter((item) =>
            selectedClient?.target_no_of_emp?.includes(item._id)
          )
          ?.map((item) => item.label),
      };
      setFormField(data);
      setEmailIds({
        ...emailIds,
        list: selectedClient?.secondaryMembers,
      });

      setInitialFieldData(data);
    }
  }, [selectedClient]);

  const handleOnEmailAddressesChange = (payload) => {
    setEmailIds(payload);
  };

  const onLoad = async () => {
    const requests = [...(id ? [await dispatch(getClientDetails(id))] : [])];

    await Promise.all(requests);
  };

  const handleOnChange = (e) => {
    setFormField({
      ...formFields,
      [e.target.name]: e.target.value,
    });

    setFormErrors({
      ...formErrors,
      [e.target.name]: "",
    });
  };

  const handleOnItemRemove = (removeItem, field) => {
    const formData: any = { ...formFields };

    const index = formData[field].indexOf(removeItem);
    if (index > -1) {
      formData[field].splice(index, 1);
    }

    setFormField({ ...formData });
  };

  const onSubmit = async (e) => {
    setBlockNavigation(false);
    e.preventDefault();

    const { errors, isError } = validateForm(
      formFields,
      initialErrors,
      CLIENT_FORM_ERROR_MAAPING
    );

    if (!isError && !emailIds.invalidEmailIds.length) {
      const selectedIndustries = industry?.data
        ?.filter((item) => formFields.target_industries.includes(item.label))
        ?.map((item) => item.label);
      const selectedTargetNoOfEmps = noOfEmp?.data
        ?.filter((item) => formFields.target_no_of_emp.includes(item.label))
        ?.map((item) => item._id);
      const selectedNoOfEmp = noOfEmp?.data?.find((item) =>
        formFields.no_of_emp.includes(item.label)
      )?._id;
      const secondaryMembers = emailIds.list.map((email) => {
        return email;
      });

      const payload = {
        firstName: formFields.firstName,
        lastName: formFields.lastName,
        email: formFields.email,
        title: formFields.title,
        company: formFields.company,
        no_of_emp: selectedNoOfEmp,
        ...(id &&
          Number(selectedClient?.budget) === 0 && {
            budget: formFields.budget,
          }),
        funding: formFields.funding,
        revenues: formFields.revenues,
        target_industries: selectedIndustries,
        target_no_of_emp: selectedTargetNoOfEmps,
        target_account: formFields.target_account,
        linkedIn: formFields.linkedIn,
        timezone: formFields.timezone,
        viewAmbassadorThreshold: formFields.viewAmbassadorThreshold || 0,
        meetingThreshold: formFields.meetingThreshold || 0,
        viewNextBatchAmbassadorThreshold:
          formFields.viewNextBatchAmbassadorThreshold || 0,
        licenseForMeeting: formFields.licenseForMeeting || 0,
        secondaryMembers,
        allowUpdateQues: formFields.allowUpdateQues,
      };

      const response: any = id
        ? await updateClient(id, payload)
        : await addClient({
            ...formFields,
            target_industries: selectedIndustries,
            linkedIn: formFields.linkedIn,
            target_no_of_emp: selectedTargetNoOfEmps,
            no_of_emp: selectedNoOfEmp,
            viewAmbassadorThreshold: formFields.viewAmbassadorThreshold || 0,
            meetingThreshold: formFields.meetingThreshold || 0,
            licenseForMeeting: formFields.licenseForMeeting || 0,
            viewNextBatchAmbassadorThreshold:
              formFields.viewNextBatchAmbassadorThreshold || 0,
          });

      if (response.success) {
        history.push(ROUTE_PATHS.CLIENTS.LIST);
      }
    } else {
      setFormErrors(errors);
    }
  };

  const onCancel = () => {
    if (initialFieldData !== formFields) {
      setShowCancelConfirm(true);
    } else {
      setShowCancelConfirm(false);
      history.push(ROUTE_PATHS.CLIENTS.LIST);
    }
  };

  const onCancelConfirm = () => {
    history.push(nextLocationPath);
  };

  const onRouteChange = (nextLocation) => {
    if (
      nextLocation.pathname === ROUTE_PATHS.CLIENTS.LIST &&
      !blockNavigation
    ) {
      return true;
    } else {
      setNextLocationPath(nextLocation.pathname);
    }

    setShowCancelConfirm(true);
    return false;
  };

  const checkIsDisabled = (item, formFields) => {
    if (
      item.field === "budget" &&
      id &&
      Number(selectedClient?.[item.field]) > 0
    ) {
      return true;
    }

    return false;
  };

  const onAllowQuestionUpdateToggle = (e) => {
    setFormField({
      ...formFields,
      allowUpdateQues: e?.target?.checked,
    });
  };

  function renderInput(item) {
    switch (item.inputType) {
      case ENUM_FORM_INPUT_TYPES.MULTY_EMAIL_INPUT:
        return (
          <EmailAddressBox
            defaultValue={selectedClient?.secondaryMembers}
            inlineLabel
            label={item.label}
            onValueChange={handleOnEmailAddressesChange}
          />
        );
      case ENUM_FORM_INPUT_TYPES.CHECKBOX:
        return (
          <AppCheckbox
            onChange={onAllowQuestionUpdateToggle}
            label={item.label}
            checked={
              formFields[item.field] !== undefined
                ? formFields[item.field]
                : false
            }
            id={item.id}
          />
        );
      case ENUM_FORM_INPUT_TYPES.MULTI_SELECTBOX:
        switch (item.field) {
          case "target_no_of_emp":
            return (
              <SelectboxWithChips
                label={item.label}
                name={item.field}
                inlineLabel
                selectedValues={formFields[item.field] || []}
                handleChange={(e) => handleOnChange(e)}
                list={noOfEmp?.data || []}
                inputFieldClass={classes.textinput}
                handleOnRemove={(removeItem) => {
                  handleOnItemRemove(removeItem, item.field);
                }}
                showChips={false}
                listProperties={{
                  field: "label",
                  value: "label",
                }}
                error={formErrors[item.field]}
              />
            );
          case "target_industries":
            return (
              <SelectboxWithChips
                label={item.label}
                name={item.field}
                inlineLabel
                selectedValues={formFields[item.field] || []}
                handleChange={(e) => handleOnChange(e)}
                list={industry?.data || []}
                inputFieldClass={classes.textinput}
                handleOnRemove={(removeItem) => {
                  handleOnItemRemove(removeItem, item.field);
                }}
                showChips={false}
                listProperties={{
                  field: "label",
                  value: "label",
                }}
                error={formErrors[item.field]}
              />
            );
          default:
            return null;
        }

      case ENUM_FORM_INPUT_TYPES.AUTO_SELECTBOX:
        switch (item.field) {
          case "timezone":
            return (
              <AutoSelectbox
                label={item.label}
                field={item.field}
                id={item.field}
                inlineLabel={true}
                inputFieldClass={classes.textinput}
                textfieldStyle={{ height: 15 }}
                list={timezones?.data || []}
                onChange={handleOnChange}
                value={formFields[item.field]}
                listProperties={{
                  field: "text",
                  value: "value",
                }}
                error={formErrors[item.field]}
                requireOnChange={item.requireOnChange}
                listType={ENUM_AUTOSELECT_LIST_TYPES.ARRAY_OF_OBJECT}
              />
            );
          case "title":
            return (
              <AutoSelectbox
                label={item.label}
                field={item.field}
                id={item.field}
                inlineLabel={true}
                inputFieldClass={classes.textinput}
                textfieldStyle={{ height: 15 }}
                list={title?.data || []}
                onChange={handleOnChange}
                listProperties={{
                  field: "label",
                  value: "label",
                }}
                requireOnChange={item.requireOnChange}
                value={formFields[item.field]}
                error={formErrors[item.field]}
              />
            );
          case "no_of_emp":
            return (
              <AutoSelectbox
                label={item.label}
                field={item.field}
                id={item.field}
                inlineLabel={true}
                inputFieldClass={classes.textinput}
                textfieldStyle={{ height: 15 }}
                list={noOfEmp?.data || []}
                onChange={handleOnChange}
                listProperties={{
                  field: "label",
                  value: "label",
                }}
                requireOnChange={item.requireOnChange}
                value={formFields[item.field]}
                error={formErrors[item.field]}
              />
            );
          default:
            return null;
        }

      default:
        return (
          <AppTextField
            id={item.field}
            name={item.field}
            label={item.label}
            disabled={
              item.isEditable === false
                ? true
                : checkIsDisabled(item, formFields)
            }
            inlineLabel
            onChange={handleOnChange}
            value={formFields[item.field]}
            error={formErrors[item.field]}
            autoComplete={"off"}
            inputFieldClass={classes.textinput}
          />
        );
    }
  }

  return (
    <Container maxWidth="md">
      {id && <Prompt when={blockNavigation} message={onRouteChange} />}

      <Grid
        item
        xs={12}
        md={12}
        sm={12}
        style={{ width: "637px" }}
        container
        spacing={2}
      >
        {CLIENT_DETAILS.map((item, index) => (
          <Grid item md={6} sm={6} xs={12} key={index}>
            {renderInput(item)}
          </Grid>
        ))}
        <Grid item md={12} sm={12} xs={12} textAlign={"center"}>
          <SaveAndCancelButton
            primaryText={id ? "Update" : "Save"}
            defaultText={"Cancel"}
            onPrimaryActionClick={onSubmit}
            onDefaultButtonClick={onCancel}
          />
        </Grid>
      </Grid>

      <AppAlertBox
        isModalOpen={showCancelConfirm}
        onConfirm={onCancelConfirm}
        onCancel={() => setShowCancelConfirm(false)}
        message={GLOBAL_TEXT.ALERT_MESSAGES.CONFIRM_CANCEL}
      />
    </Container>
  );
}

export { AddOrUpdateClient };
