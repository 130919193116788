import { Component } from "react";
import { Switch, Redirect, Route, withRouter } from "react-router";
import { validateToken } from "../apis/auth.api";
import { compose } from "redux";
import { adminLayoutHOC, appLayoutHOC } from "../HOC/layouts";
import { AuthRoutes } from "./auth";
import { Unauthorized, PageNotFound } from "../components/errors";
import { ClientAmbassadorSlotSelection } from "../screens/client-ambassador-slot-selection";
import { AmbassadorClientSlotSelection } from "../screens/ambassador-client-slot-selection";
import { ClientRoutes } from "./client";
import { AmbassadorRoutes } from "./ambassador";
import { InvitationRoutes } from "./invitations";
import { AdminDashboard } from "../screens/dashboard";
import { ROUTE_PATHS } from "./route-paths";
import AmbassadorSimpleList from "../screens/ambassadors/list/simple-list";
import { ConnectRequests } from "../screens/connect";
import Opportunity from "../screens/opportunity";
import {
  getAmbassadorTypeList,
  getCategoryList,
  getIndustryList,
  getIntrestedInPriorityList,
  getNoOfEmpList,
  getOpportunityCategoryList,
  getStateList,
  getTimezoneList,
  getTitleList,
  getTopicList,
} from "../apis/common.api";
import { connect } from "react-redux";
import { MarketPlaceRoutes } from "./marketplace";
import VendorReviews from "../screens/reviews";
import ReviewReport from "../screens/review-report";
import { checkIsNoInitialConfigRequiredRoutes } from "../services/utils";
interface State {
  appLoading: boolean;
}

class Routes extends Component<any, State> {
  constructor(props) {
    super(props);
    this.state = {
      appLoading: true,
    };
  }

  async componentDidMount() {
    const initRequest = [...[localStorage.getItem("token") && validateToken()]];

    if (checkIsNoInitialConfigRequiredRoutes()) {
      this.props.dispatchSetInitialListFetched(true);
      this.setState({
        appLoading: false,
      });
      return;
    }

    Promise.all(initRequest).then((res: any) => {
      if (res?.[0]?.success) {
        Promise.all([
          this.props.dispatchGetTimezoneList(),
          this.props.dispatchGetIndustryList(),
          this.props.dispatchGetTitleList(),
          this.props.dispatchGetNoOfEmpList(),
          this.props.dispatchGetCategoryList(),
          this.props.dispatchGetOpportunityCategoryList(),
          this.props.dispatchGetAmbassadorTypeList(),
          this.props.dispatchGetTopicList(),
          this.props.dispatchGetStateList(),
          this.props.dispatchGetIntrestedInPriorityList(),
        ]).finally(() => {
          this.props.dispatchSetInitialListFetched(true);
        });
      }

      this.setState({
        appLoading: false,
      });
    });
  }

  render() {
    const { appLoading } = this.state;

    return (
      !appLoading && (
        <Switch>
          <Redirect exact path={ROUTE_PATHS.HOME} to={ROUTE_PATHS.auth.LOGIN} />

          <Redirect
            exact
            path={ROUTE_PATHS.ADMIN_HOME}
            to={ROUTE_PATHS.DASHBOARD}
          />

          <Route
            exact
            path={ROUTE_PATHS.TYPEFORM_REVIEW_REPORT}
            component={compose(withRouter)(ReviewReport)}
          />

          <Route
            exact
            path={ROUTE_PATHS.TYPEFORM_REVIEW_REPORT_ALL}
            component={compose(withRouter)(ReviewReport)}
          />

          <Route
            exact
            path={ROUTE_PATHS.SIMPLE_LIST}
            component={AmbassadorSimpleList}
          />

          <Route
            exact
            path={ROUTE_PATHS.DASHBOARD}
            component={compose(withRouter, adminLayoutHOC)(AdminDashboard)}
          />

          <Route
            exact
            path={ROUTE_PATHS.REVIEWS}
            component={compose(withRouter, adminLayoutHOC)(VendorReviews)}
          />

          <Route path={ROUTE_PATHS.CLIENTS.ROOT} component={ClientRoutes} />

          <Route
            path={ROUTE_PATHS.AMBASSADORS.ROOT}
            component={AmbassadorRoutes}
          />
          <Route
            path={ROUTE_PATHS.MARKETPLACE.ROOT}
            component={MarketPlaceRoutes}
          />
          <Route
            path={ROUTE_PATHS.INVITATIONS.ROOT}
            component={InvitationRoutes}
          />

          <Route
            exact
            path={ROUTE_PATHS.CLIENT_AMBASSADOR_SLOT_SELECTION}
            component={compose(
              withRouter,
              appLayoutHOC
            )(ClientAmbassadorSlotSelection)}
          />

          <Route
            exact
            path={ROUTE_PATHS.AMBASSADOR_CLIENT_SLOT_SELECTION}
            component={compose(
              withRouter,
              appLayoutHOC
            )(AmbassadorClientSlotSelection)}
          />

          <Route
            exact
            path={ROUTE_PATHS.CONNECT_REQUESTS}
            component={compose(withRouter, adminLayoutHOC)(ConnectRequests)}
          />

          <Route
            exact
            path={ROUTE_PATHS.OPPORTUNITY}
            component={compose(withRouter, adminLayoutHOC)(Opportunity)}
          />

          <Route
            path={ROUTE_PATHS.UNAUTHORIZED}
            exact={true}
            component={Unauthorized}
          />

          <Route path="/" component={AuthRoutes} />

          <Route
            path={ROUTE_PATHS.NOT_FOUND}
            exact={true}
            component={PageNotFound}
          />

          <Redirect to={ROUTE_PATHS.NOT_FOUND} />
        </Switch>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchGetTimezoneList: () => dispatch(getTimezoneList()),
    dispatchGetIndustryList: () => dispatch(getIndustryList()),
    dispatchGetTitleList: () => dispatch(getTitleList()),
    dispatchGetNoOfEmpList: () => dispatch(getNoOfEmpList()),
    dispatchGetStateList: () => dispatch(getStateList()),
    dispatchGetCategoryList: () => dispatch(getCategoryList()),
    dispatchGetOpportunityCategoryList: () =>
      dispatch(getOpportunityCategoryList()),
    dispatchGetAmbassadorTypeList: () => dispatch(getAmbassadorTypeList()),
    dispatchGetTopicList: () => dispatch(getTopicList()),
    dispatchGetIntrestedInPriorityList: () =>
      dispatch(getIntrestedInPriorityList()),
    dispatchSetInitialListFetched: (payload) =>
      dispatch({ type: "SET_INITIAL_LIST_FETCHED", payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Routes));
