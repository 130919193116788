import { useEffect } from "react";
import { useSelector } from "react-redux";
import { CLIENT_INVITATION_STATUS_LIST_COLUMNS } from "./table-config";
import { Box, Grid, TextField } from "@mui/material";
import clientHOC from "../../client-hoc";
import { ClientInvitationStatusListTable } from "./table";
import { useStyles } from "./styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function ClientInvitationStatus({
  getClientInvitationStatusList,
  clientInvitationStatusList,
}: any) {
  const classes = useStyles();
  const { selectedPage, selectedPageSize, selectedDate } = useSelector(
    (state: any) => state.clients.info.invitationStatus.filters
  );

  useEffect(() => {
    getClientInvitationStatusList({
      selectedPage,
      selectedPageSize,
      selectedDate,
    });
  }, []);

  const handlePageFilters = ({ selectedPage, selectedPageSize }) => {
    getClientInvitationStatusList({
      selectedPage,
      selectedPageSize,
    });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Grid container spacing={2} mt={1} direction="column">
        <Grid item container justifyContent={"flex-end"}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={selectedDate || null}
              componentsProps={{
                actionBar: {
                  actions: ["clear"],
                },
              }}
              onChange={(date) => {
                getClientInvitationStatusList({
                  selectedPage: 1,
                  selectedPageSize,
                  selectedDate: date ? date["$d"] : "", // Using formatted date string
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "", // Remove the default placeholder
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item>
          <ClientInvitationStatusListTable
            list={clientInvitationStatusList}
            columns={CLIENT_INVITATION_STATUS_LIST_COLUMNS}
            handlePageFilters={handlePageFilters}
            appliedFilters={{
              selectedPageSize,
              selectedPage,
            }}
          ></ClientInvitationStatusListTable>
        </Grid>
      </Grid>
    </Box>
  );
}

export default clientHOC(ClientInvitationStatus);
