const getData = (r) => {
  switch (r.type) {
    case "rating":
      const ratings = ["0", "1", "2", "3", "4", "5"];
      const processedData = r?.answers.map((item) => {
        return {
          ...item,
          answer: !ratings.includes(item.answer) ? "0" : item.answer,
        };
      });

      const completeData = ratings.map((rating) => {
        const existing = processedData.find((item) => item.answer === rating);
        return existing
          ? existing
          : { answer: rating, count: 0, percentage: 0, total: 0 };
      });

      const totalResponses = completeData.reduce(
        (sum, item) => sum + item.count,
        0
      );
      const weightedSum = completeData.reduce((sum, item) => {
        return item.answer > 0 ? sum + item.answer * item.count : sum;
      }, 0);
      const avgRating =
        totalResponses > 0 ? (weightedSum / totalResponses).toFixed(1) : 0;

      return {
        avgRating: r?.averageRating,
        percentages: completeData.map((a) => a.percentage),
        totalResponses: completeData.map((a) => a.count),
      };

    case "radio":
      const answers = r?.answers?.map((a) => a.answer);
      return {
        options: ["Yes", "No"]?.map((a) => {
          if (answers.includes(a)) {
            return {
              answer: a,
              count: r?.answers?.find((ans) => ans.answer === a)?.count,
              percentage: r?.answers?.find((ans) => ans.answer === a)
                ?.percentage,
              total: r?.answers?.find((ans) => ans.answer === a)?.total,
            };
          } else {
            return {
              answer: a,
              count: 0,
              percentage: 0,
              total: 0,
            };
          }
        }),
      };
    case "multi_select":
      return {
        options: r?.answers,
      };
    case "text":
      return {
        responses: r?.answers?.[0]?.answer,
      };
    default:
      return {};
  }
};

export function generateReportDataFromResponse(data) {
  return data?.report?.map((r) => {
    return {
      question: r.question,
      totalResponses: data?.totalAns,
      totalFeedbacks: data?.totalAns,
      type: r?.type,
      data: getData(r),
    };
  });
}
