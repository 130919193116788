import { Card, CardContent, Grid, Typography } from "@mui/material";
import { ENUM_CURRENCY } from "../../../../enums";
import { numberFormat } from "../../../../services/utils";

const SUMMARY = [
  { label: "Hold Amount", field: "holdAmount", btColor: "#F8D58B" },
  { label: "Profit", field: "pendingAmount", btColor: "#F65C8D" },
  { label: "Revenue", field: "budget", btColor: "#41C197" },
  {
    label: "Spent Amount",
    field: "total",
    btColor: "#5D78FF",
  },
];

function SummaryItem({ item, data }) {
  const handleRenderer = () => {
    switch (item.renderer) {
      case "USED_AMOUNT_RENDERER":
        return numberFormat(
          data?.budget - (data?.holdAmount + data?.pendingAmount),
          ENUM_CURRENCY.USD
        );
      default:
        return numberFormat(data?.[item.field], ENUM_CURRENCY.USD);
    }
  };

  return (
    <Grid item xs={12} sm={3}>
      <Card
        elevation={5}
        sx={{
          borderTop: `7px solid ${item.btColor}`,
          height: 134,
          borderRadius: 0,
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            height: 134 - 7,
          }}
        >
          <Typography fontWeight={500} fontSize={14}>
            {item.label}
          </Typography>
          <Typography
            fontWeight={500}
            fontSize={24}
            sx={{ color: item.btColor }}
            variant="caption"
          >
            {handleRenderer()}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}

function ClientPaymentSummary({ data }: any) {
  return (
    <>
      {SUMMARY.map((item, index) => (
        <SummaryItem key={index} item={item} data={data} />
      ))}
    </>
  );
}

export { ClientPaymentSummary };
