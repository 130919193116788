import {
  Button,
  FormControlLabel,
  FormControlLabelProps,
  Grid,
  Radio,
  RadioGroup,
  styled,
  useRadioGroup,
} from "@mui/material";
import React from "react";
import ReviewPageVendorSearch from "./vendor-search";
import { convertToUrl } from "../../services/utils";
import { ROUTE_PATHS } from "../../routes/route-paths";
import { useHistory } from "react-router";
import ReviewPageAmbassadorSearch from "./ambassador-search";

interface StyledFormControlLabelProps extends FormControlLabelProps {
  checked: boolean;
}

const StyledFormControlLabel = styled((props: StyledFormControlLabelProps) => (
  <FormControlLabel {...props} />
))(({ theme }: any) => ({
  variants: [
    {
      props: { checked: true },
      style: {
        ".MuiFormControlLabel-label": {
          color: theme.palette.primary.main,
        },
      },
    },
  ],
}));

function CustomFormControlLabel(props: FormControlLabelProps) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
}

export default function GenerateReviewReportModal() {
  const [selectedOption, setSelectedOption] = React.useState("all");
  const [clientId, setClientId] = React.useState("");
  const [ambassadorId, setAmbassadorId] = React.useState("");
  const history = useHistory();

  const handleOptionChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);

    setClientId("");
  };

  const handleClientChange = (event, value) => {
    const clientId = value?.id;

    setClientId(clientId);
  };

  const handleAmbassadorChange = (event, value) => {
    const ambassadorId = value?.id;

    setAmbassadorId(ambassadorId);
  };

  const onGenerateClick = () => {
    const url = convertToUrl(ROUTE_PATHS.TYPEFORM_REVIEW_REPORT, {
      ":id": selectedOption === "selectVendor" ? clientId : "all",
      ":ambassadorId": ambassadorId ?? "",
    });

    window.open(url, "_blank");
  };

  const isDisabled = selectedOption === "selectVendor" && !clientId;

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RadioGroup
            row
            name="use-radio-group"
            defaultValue="all"
            onChange={handleOptionChange}
          >
            <CustomFormControlLabel
              value="all"
              label="All"
              control={<Radio />}
            />
            <CustomFormControlLabel
              value="selectVendor"
              label="Browse"
              control={<Radio />}
            />
          </RadioGroup>
        </Grid>
        {selectedOption === "selectVendor" && (
          <Grid item xs={12}>
            <label style={{ fontSize: 14 }}>Search</label>
            <ReviewPageVendorSearch onChange={handleClientChange} />
          </Grid>
        )}
        {clientId && (
          <Grid item xs={12}>
            <label style={{ fontSize: 14 }}>Search</label>
            <ReviewPageAmbassadorSearch
              onChange={handleAmbassadorChange}
              clientId={clientId}
            />
          </Grid>
        )}

        <Grid item xs={12} justifyContent={"flex-end"} textAlign={"right"}>
          <Button
            onClick={onGenerateClick}
            disabled={isDisabled}
            variant="contained"
            color="primary"
          >
            Generate
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
