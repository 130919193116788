import { Grid, Typography } from "@mui/material";

const styles = {
  label: {
    color: "#6B778C",
    fontWeight: 700,
    fontSize: 14,
  },
  value: {
    fontSize: 14,
    color: "black",
  },
};

function LabelAndValue({
  label,
  value,
  renderLabel,
  renderValue,
  labelSize,
  valueSize,
  labelxs = 3,
  labelsm = 3,
  labelmd = 3,
  valuexs = undefined,
  valuesm = undefined,
  valuemd = undefined,
  size,
  sx,
  valueColor,
  labelColor,
  fontWeight,
}: any) {
  return (
    <Grid
      container
      sx={{
        ...sx,
      }}
    >
      <Grid item xs={labelxs} md={labelmd} sm={labelsm}>
        {renderLabel !== undefined && renderLabel}
        {label && (
          <Typography
            style={{
              ...styles.label,
              ...(labelColor && { color: labelColor }),
              ...(labelSize && { fontSize: labelSize }),
              ...(size && { fontSize: size }),
              ...(fontWeight && { fontWeight: fontWeight.label }),
            }}
            component={"span"}
          >{`${label}: `}</Typography>
        )}
      </Grid>
      <Grid
        item
        xs={valuexs ?? 12 - labelxs}
        md={valuemd ?? 12 - labelmd}
        sm={valuesm ?? 12 - labelsm}
      >
        {renderValue !== undefined && renderValue}
        {value && (
          <Typography
            style={{
              ...styles.value,
              ...(valueColor && { color: valueColor }),
              ...(valueSize && { fontSize: valueSize }),
              ...(size && { fontSize: size }),
              ...(fontWeight && { fontWeight: fontWeight.value }),
            }}
            component={"span"}
          >
            {value}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

export { LabelAndValue };
