import React, { useMemo } from "react";
import { trucatedText } from "../../../services/utils";
import { IMAGES_PATH } from "../../../constants";
import {
  DATE_FORMATS,
  formatDate,
} from "../../../services/date-and-time.utils";
import "./style.scss";
import {
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(10),
    border: "1px solid #dadde9",
  },
}));

interface ColumnConfig {
  header: any;
  accessor: (row: any) => string | React.ReactNode;
  className?: string;
  dataClassName?: string;
}

interface ReviewTableProps {
  reviews: any[];
  ratingColumns?: Answer[];
  loading?: boolean;
}

interface Answer {
  a: string;
  q: string;
  type: string;
}

interface Review {
  answers: Answer[];
  createdAt: string;
}

const TYPE_ICONS_MAP = {
  rating: { icon: "star.svg", className: "yellow-bg" },
  multi_select: { icon: "correct.svg", className: "pink-bg" },
  radio: { icon: "contrast.svg", className: "pink-bg" },
  text: { icon: "line-style.svg", className: "blue-bg" },
};

const ReviewTable: React.FC<ReviewTableProps> = ({
  reviews,
  ratingColumns,
  loading = false,
}) => {
  const columnConfig: ColumnConfig[] = [
    {
      header: "Date",
      accessor: (row: Review) =>
        row?.createdAt
          ? formatDate(row?.createdAt, DATE_FORMATS.fullDateWithTime)
          : "Not available",
      dataClassName: "first-col",
    },
    {
      header: "Response Type",
      accessor: (row: Review) => <span className="tag">Completed</span>,
    },
    ...(Array.isArray(ratingColumns)
      ? ratingColumns.map((answer, index) => ({
          header: (
            <div
              className={`table-header-title ${
                TYPE_ICONS_MAP[answer.type]?.className
              }`}
            >
              <span>
                <img
                  src={`${IMAGES_PATH}/${TYPE_ICONS_MAP[answer.type]?.icon}`}
                  alt={answer.type}
                />
              </span>
              <HtmlTooltip arrow title={answer.q} placement="top">
                <label>{trucatedText(answer.q, 30)}</label>
              </HtmlTooltip>
            </div>
          ),
          accessor: (row: Review) => formatAnswer(row.answers[index]),
        }))
      : []),
  ];

  const formatAnswer = (item: Answer) => {
    switch (item.type) {
      case "radio":
        return (
          <span className={`tag ${item.a === "No" ? "red-tag" : ""}`}>
            {item.a}
          </span>
        );
      case "rating":
        return !Number(item.a)
          ? item.a ?? <Typography variant="caption">Preffered not to answer</Typography>
          : `${item.a}/5`;
      default:
        return (
          <HtmlTooltip arrow title={item.a} placement="top">
            {trucatedText(item.a, 40)}
          </HtmlTooltip>
        );
    }
  };

  const renderTableHeaders = useMemo(() => {
    return columnConfig.map((column, index) => (
      <div className="table-td" key={`table-header-${index}`}>
        <div className={`table-header-title ${column.className || ""}`}>
          <label>{column.header}</label>
        </div>
      </div>
    ));
  }, [columnConfig]);

  const renderTableRows = useMemo(() => {
    return reviews.length ? (
      reviews.map((review, rowIndex) => (
        <div className="table-row" key={`vendor-review-table-row-${rowIndex}`}>
          {columnConfig.map((column, colIndex) => (
            <div
              className={`table-td ${column?.dataClassName} || ""`}
              key={`cell-${colIndex}`}
            >
              <label>{column.accessor(review)}</label>
            </div>
          ))}
        </div>
      ))
    ) : (
      <div className="table-row">
        <div className="table-td">
          <label>No data found</label>
        </div>
      </div>
    );
  }, [reviews, columnConfig]);

  return (
    <div className="typeform">
      <section className="result-table-section">
        <div className="table-row table-header">{renderTableHeaders}</div>
        {loading ? (
          <div
            className="data-loader"
            style={{ display: loading ? "" : "none" }}
          >
            <img src={`${IMAGES_PATH}/loader.svg`} />
          </div>
        ) : (
          renderTableRows
        )}
      </section>
    </div>
  );
};

export default ReviewTable;
