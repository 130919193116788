import { Container, Divider, IconButton, Typography } from "@mui/material";
import FlexBox from "../../components/layouts/flexbox";
import TypeformQuestionResponse from "../../components/review-report-graphs/responses";
import React, { useEffect } from "react";
import {
  ErrorMessage,
  FeedbackReportLink,
  ReviewReportSkeleton,
} from "./components";
import { fetchReviewReportDetails } from "../../apis/typeform.api";
import { generateReportDataFromResponse } from "./utils";
import { useParams } from "react-router";
import PrintIcon from "@mui/icons-material/Print";

export default function ReviewReport() {
  const [fetching, setFetching] = React.useState(true);
  const [reportData, setReportData] = React.useState([]);
  const [response, setResponse]: any = React.useState([]);
  const [serverError, setServerError] = React.useState("");
  const param: any = useParams();

  useEffect(() => {
    fetchReportData();
  }, []);

  const fetchReportData = async () => {
    setFetching(true);

    const payload =
      param?.id === "all"
        ? {}
        : { clientId: param?.id, ambassadorId: param?.ambassadorId ?? "" };
    try {
      const response = await fetchReviewReportDetails(payload);

      if (response?.success) {
        const responseData = generateReportDataFromResponse(response?.data);
        setResponse(response?.data);
        setReportData(responseData);
      } else {
        setServerError("Failed to fetch report data");
      }
    } catch (error) {
      setServerError("Failed to fetch report data");
    } finally {
      setFetching(false);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  if (fetching) {
    return (
      <Container sx={{ py: 2 }}>
        <ReviewReportSkeleton />
      </Container>
    );
  }

  if (serverError) {
    return (
      <Container sx={{ py: 2 }}>
        <ErrorMessage />
      </Container>
    );
  }

  return (
    <Container sx={{ py: 2 }}>
      <FlexBox
        mb={2}
        justifyContent={"space-between"}
        alignItems={"center"}
        gap={2}
      >
        <FlexBox flexDirection={"column"}>
          <Typography fontFamily={"Times"} variant="h4">
            {response?.clientName || "Vendor Review"}
            {response?.ambassadorName && <> : {response?.ambassadorName} </>}
          </Typography>
          <Typography
            color={"textSecondary"}
            variant="caption"
            fontFamily={"Times"}
          >
            {response?.totalAns} Responses
          </Typography>
        </FlexBox>
        <FlexBox width={"50%"} gap={2} className="no-print">
          <FeedbackReportLink
            url={
              process.env.REACT_APP_CONNECT_APP_URL +
              `/review-report/${response?.id}`
            }
          />
          <IconButton onClick={handlePrint} title="Print">
            <PrintIcon color="info" />
          </IconButton>
        </FlexBox>
      </FlexBox>
      <Divider />
      {reportData?.map((question, index) => (
        <React.Fragment key={`typeform-question-report-${index}`}>
          <TypeformQuestionResponse
            question={question.question}
            totalResponses={question.totalResponses}
            totalFeedbacks={question.totalFeedbacks}
            type={question.type}
            data={question.data}
          />
          <Divider />
        </React.Fragment>
      ))}
    </Container>
  );
}
