import { ROUTE_PATHS } from "../../../routes/route-paths";
import SvgIcons from "../../../svgs";
import { AMBASSADOR_BREADCRUMB } from "./ambassadors";
import { CLIENT_BREADCRUMB } from "./clients";
import { INVITATIONS_BREADCRUMB } from "./invirations";
import { APP_BREADCRUMB } from "./app";
import { MODULE_ICON_MAP } from "../../../constants/moduleicon";

export const BREADCRUMBS: any = {
  [ROUTE_PATHS.DASHBOARD]: {
    isRoot: true,
    paths: [{ label: "Dashboard", icon:  MODULE_ICON_MAP.DASHBOARD }],
    title: "Dashboard",
  },
  ...CLIENT_BREADCRUMB,
  ...AMBASSADOR_BREADCRUMB,
  ...INVITATIONS_BREADCRUMB,
  ...APP_BREADCRUMB
};
