import {
  DEFAULT_SELECTED_PAGE,
  DEFAULT_SELECTED_PAGE_SIZE,
} from "../../../constants";

const SET_CLIENT_MEETINGS_LIST_LOADING = "SET_CLIENT_MEETINGS_LIST_LOADING";
const SET_CLIENT_MEETINGS_LIST = "SET_CLIENT_MEETINGS_LIST";
const SET_CLIENT_MEETINGS_FILTERS = "SET_CLIENT_MEETINGS_FILTERS";
const SET_CLIENT_PAYMENTS_LIST_LOADING = "SET_CLIENT_PAYMENTS_LIST_LOADING";
const SET_CLIENT_PAYMENTS_LIST = "SET_CLIENT_PAYMENTS_LIST";
const SET_CLIENT_CAMPAIGNS_LIST_LOADING = "SET_CLIENT_CAMPAIGNS_LIST_LOADING";
const SET_CLIENT_CAMPAIGNS_LIST = "SET_CLIENT_CAMPAIGNS_LIST";
const SET_CLIENT_PAYMENTS_FILTERS = "SET_CLIENT_PAYMENTS_FILTERS";
const RESET_CLIENT_INFO = "RESET_CLIENT_INFO";
const SET_CLIENT_INVITATION_STATUS_LIST_LOADING =
  "SET_CLIENT_INVITATION_STATUS_LIST_LOADING";
const SET_CLIENT_INVITATION_STATUS_LIST = "SET_CLIENT_INVITATION_STATUS_LIST";
const SET_CLIENT_INVITATION_STATUS_FILTERS =
  "SET_CLIENT_INVITATION_STATUS_FILTERS";

const initialClientInfoState = {
  meetings: {
    data: undefined,
    loading: null,
    filters: {
      selectedPage: DEFAULT_SELECTED_PAGE,
      selectedPageSize: DEFAULT_SELECTED_PAGE_SIZE,
    },
    total: undefined,
  },
  payments: {
    data: undefined,
    loading: null,
    filters: {
      selectedPage: DEFAULT_SELECTED_PAGE,
      selectedPageSize: DEFAULT_SELECTED_PAGE_SIZE,
    },
    total: undefined,
  },
  campaigns: {
    data: undefined,
    loading: null,
    filters: {
      selectedPage: DEFAULT_SELECTED_PAGE,
      selectedPageSize: DEFAULT_SELECTED_PAGE_SIZE,
    },
    total: undefined,
  },
  invitationStatus: {
    data: undefined,
    loading: null,
    filters: {
      selectedPage: DEFAULT_SELECTED_PAGE,
      selectedPageSize: DEFAULT_SELECTED_PAGE_SIZE,
      selectedDate: "",
    },
    total: undefined,
  },
};

const setClientMeetinsListLoading = (payload) => ({
  type: SET_CLIENT_MEETINGS_LIST_LOADING,
  payload,
});

const setClientMeetinsList = (payload) => ({
  type: SET_CLIENT_MEETINGS_LIST,
  payload,
});

const setClientMeetingsFilters = (payload = undefined) => ({
  type: SET_CLIENT_MEETINGS_FILTERS,
  payload,
});

const setClientInvitationStatusListLoading = (payload) => ({
  type: SET_CLIENT_INVITATION_STATUS_LIST_LOADING,
  payload,
});

const setClienInvitationStatusList = (payload) => ({
  type: SET_CLIENT_INVITATION_STATUS_LIST,
  payload,
});

const setClientInvitationStatusFilters = (payload = undefined) => ({
  type: SET_CLIENT_INVITATION_STATUS_FILTERS,
  payload,
});

const setClientPaymentsListLoading = (payload) => ({
  type: SET_CLIENT_PAYMENTS_LIST_LOADING,
  payload,
});

const setClientPaymentsList = (payload) => ({
  type: SET_CLIENT_PAYMENTS_LIST,
  payload,
});

const setClientCampaignsListLoading = (payload) => ({
  type: SET_CLIENT_CAMPAIGNS_LIST_LOADING,
  payload,
});

const setClientCampaignsList = (payload) => ({
  type: SET_CLIENT_CAMPAIGNS_LIST,
  payload,
});

const setClienPaymentsFilters = (payload = undefined) => ({
  type: SET_CLIENT_PAYMENTS_FILTERS,
  payload,
});

const resetClientInfo = () => ({
  type: RESET_CLIENT_INFO,
});

export {
  initialClientInfoState,
  setClientMeetinsListLoading,
  setClientMeetinsList,
  setClientMeetingsFilters,
  setClientPaymentsListLoading,
  setClientPaymentsList,
  setClienPaymentsFilters,
  resetClientInfo,
  setClienInvitationStatusList,
  setClientInvitationStatusFilters,
  setClientInvitationStatusListLoading,
  setClientCampaignsListLoading,
  setClientCampaignsList,
  SET_CLIENT_MEETINGS_LIST_LOADING,
  SET_CLIENT_MEETINGS_LIST,
  SET_CLIENT_MEETINGS_FILTERS,
  SET_CLIENT_PAYMENTS_LIST_LOADING,
  SET_CLIENT_PAYMENTS_LIST,
  SET_CLIENT_PAYMENTS_FILTERS,
  RESET_CLIENT_INFO,
  SET_CLIENT_INVITATION_STATUS_LIST_LOADING,
  SET_CLIENT_INVITATION_STATUS_LIST,
  SET_CLIENT_INVITATION_STATUS_FILTERS,
  SET_CLIENT_CAMPAIGNS_LIST_LOADING,
  SET_CLIENT_CAMPAIGNS_LIST,
};
