import {
  DEFAULT_SELECTED_PAGE,
  DEFAULT_SELECTED_PAGE_SIZE,
} from "../../../constants";

const SET_INVITATIONS_LIST_LOADING = "SET_INVITATIONS_LIST_LOADING";
const SET_INVITATIONS_LIST = "SET_INVITATIONS_LIST";
const SET_INVITATION_LIST_FILTERS = "SET_INVITATION_LIST_FILTERS";
const RESET_INVITATION_LIST_FILTERS = "RESET_INVITATION_LIST_FILTERS";

const initialInvitationListState = {
  data: undefined,
  loading: null,
  filters: {
    selectedPage: DEFAULT_SELECTED_PAGE,
    selectedPageSize: DEFAULT_SELECTED_PAGE_SIZE,
    searchValue: "",
    selectedDate: "",
    status: "",
  },
  total: undefined,
};

const setInvitationsListLoading = (payload) => ({
  type: SET_INVITATIONS_LIST_LOADING,
  payload,
});

const setInvitationsList = (payload) => ({
  type: SET_INVITATIONS_LIST,
  payload,
});

const setInvitationListFilters = (payload = undefined) => ({
  type: SET_INVITATION_LIST_FILTERS,
  payload,
});

const resetInvitationListFilters = () => ({
  type: RESET_INVITATION_LIST_FILTERS,
});

export {
  initialInvitationListState,
  setInvitationsListLoading,
  setInvitationsList,
  setInvitationListFilters,
  resetInvitationListFilters,
  SET_INVITATIONS_LIST_LOADING,
  SET_INVITATIONS_LIST,
  SET_INVITATION_LIST_FILTERS,
  RESET_INVITATION_LIST_FILTERS
};
