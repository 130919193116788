import { Grid } from "@material-ui/core";
import { useState } from "react";
import { SaveAndCancelButton } from "../../../../components/buttons";
import { Box } from "@mui/material";
import { EmailAddressBox } from "../../../components/email-addressbox";

function InvitationAcceptModal({ onAcceptSubmit, onAcceptCancel }) {
  const [emailIds, setEmailds]: any = useState({
    list: [],
    invalidEmailIds: [],
  });

  const handleOnEmailAddressesChange = (payload) => {
    setEmailds(payload);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!emailIds.invalidEmailIds.length) {
      onAcceptSubmit({
        additionalMembers: emailIds.list.map((email) => {
          return { email };
        })
      });
    } else {
      
    }
  };

  return (
    <Grid container direction="row" style={{ marginBottom: 10 }}>
      <Grid
        item
        xs={12}
        md={12}
        sm={12}
        container
        spacing={2}
        style={{ marginBottom: 35 }}
      >
        <Grid item xs={12}>
          <EmailAddressBox inlineLabel onValueChange={handleOnEmailAddressesChange} />
        </Grid>
      </Grid>
      <Grid item xs={12} container justifyContent="flex-end">
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <SaveAndCancelButton
            primaryText={"Submit"}
            defaultText={"Cancel"}
            onPrimaryActionClick={onSubmit}
            onDefaultButtonClick={onAcceptCancel}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export { InvitationAcceptModal };
