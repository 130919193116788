import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useHistory } from "react-router";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Popover,
  Toolbar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import { drawerWidth } from "../../../../constants";
import { MouseEvent, useState } from "react";
import { Logo } from "../../../logo";
import FlexBox from "../../flexbox";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { isDesktop } from "react-device-detect";

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    borderRight: "none !important",
    position: "fixed",
    whiteSpace: "nowrap",
    backgroundColor: "#070639",
    width: open ? drawerWidth : theme.spacing(0),
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    boxSizing: "border-box",
    [theme.breakpoints.up("sm")]: {
      width: open ? drawerWidth : theme.spacing(0),
    },
  },
}));

function AdminPanelSidebar({ list, selected, open, onToggle }) {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnItemClick = (item) => {
    history.push(item.path);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Drawer variant="permanent" open={open}>
      <FlexBox
        flexDirection="column"
        height="100vh"
        justifyContent="space-between"
      >
        <Box>
          <Toolbar
            sx={{
              display: { lg: "none", xs: "flex" },
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            {!isDesktop && (
              <IconButton onClick={onToggle}>
                <ChevronLeftIcon sx={{ color: "white" }} />
              </IconButton>
            )}
          </Toolbar>
          <Divider />
          <Box
            component={"div"}
            sx={{ display: "flex", visibility: open ? "visible" : "hidden" }}
            justifyContent={"center"}
            alignItems="center"
            style={{ marginTop: 37, marginBottom: 42 }}
          >
            <Logo />
            <IconButton onClick={handleClick}>
              <ExpandMoreIcon htmlColor="white" />
            </IconButton>
            <Popover
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              id={id}
              onClose={handleClose}
              open={openPopover}
              anchorEl={anchorEl}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <List>
                <ListItem>
                  <Typography fontSize={14} fontWeight="400">
                    Select Organization
                  </Typography>
                </ListItem>
                <Divider />
                <ListItem>
                  <Typography
                    fontSize={14}
                    fontWeight="400"
                    sx={{ color: "#666666", mt: 1, mb: 1 }}
                  >
                    Execweb (current)
                  </Typography>
                </ListItem>
                <ListItem>
                  <Button
                    onClick={() => {}}
                    fullWidth
                    sx={{
                      fontSize: 11,
                      width: 162,
                      color: "#070639",
                      height: 36,
                      mt: 2,
                      fontWeight: 500,
                      p: 0,
                    }}
                    variant="outlined"
                  >
                    create new organisation
                  </Button>
                </ListItem>
              </List>
            </Popover>
          </Box>
          <List>
            {list.map((item, index) => (
              <ListItem key={index} disablePadding sx={{ mb: 4 }}>
                <ListItemButton onClick={() => handleOnItemClick(item)}>
                  <ListItemIcon
                    sx={{
                      color:
                        selected?.label === item.label ? "#FFFFFF" : "#979797",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <Typography
                    sx={{
                      color:
                        selected?.label === item.label ? "#FFFFFF" : "#979797",
                      fontWeight: selected ? 700 : 500,
                      fontSize: 14,
                    }}
                  >
                    {item.label}
                  </Typography>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </FlexBox>
    </Drawer>
  );
}

export { AdminPanelSidebar };
