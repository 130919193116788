import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { INVITATION_LIST_COLUMNS } from "./table-config";
import { useStyles } from "./styles";
import { ButtonWithIcon } from "../../../components/buttons";
import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import invitationHOC from "../invitation-hoc";
import { AppDatePicker } from "../../../components/datepickers";
import { InvitationListTable } from "./table";
import { AutoSelectbox } from "../../../components/selectbox/autoselect";
import { searchClients } from "../../../apis/clients.api";
import { resetInvitationListFilters } from "../../../redux/modules/invitations/list-actions";
import { ENUM_PROJECT_STATUS } from "../../../enums";
import { CONST_PROJECT_STATUS_LIST } from "../../../constants/lists";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const statuses = [
  {
    value: ENUM_PROJECT_STATUS.ARCHIVE,
    displayLabel:
      CONST_PROJECT_STATUS_LIST[ENUM_PROJECT_STATUS.ARCHIVE].displayLabel,
  },
  {
    value: ENUM_PROJECT_STATUS.DONE,
    displayLabel:
      CONST_PROJECT_STATUS_LIST[ENUM_PROJECT_STATUS.DONE].displayLabel,
  },
  {
    value: ENUM_PROJECT_STATUS.SEND_MEETING_LINK,
    displayLabel:
      CONST_PROJECT_STATUS_LIST[ENUM_PROJECT_STATUS.SEND_MEETING_LINK]
        .displayLabel,
  },
];

function InvitationList({
  onNewInvitationClick,
  getInvitationList,
  invitationList,
  onInvitationRejectClick,
  onInvitationAceeptClick,
  onInvitationDeleteClick,
  onRescheduleInvitationClick,
}: any) {
  const classes = useStyles();
  const [clientName, setClientName] = useState("");
  const [clientList, setClientList] = useState([]);
  const [clientSelectionError, setClientSelectionError] = useState("");
  const [selectedClient, setSelectedClient] = useState({
    id: "",
    value: "",
  });

  const { selectedPage, selectedPageSize, searchValue, selectedDate, status } =
    useSelector((state: any) => state.invitations.list.filters);
  const dispatch = useDispatch();

  useEffect(() => {
    getInvitationList({
      selectedPage,
      selectedPageSize,
      searchValue,
      selectedDate,
      status: ENUM_PROJECT_STATUS.SEND_MEETING_LINK,
    });

    return () => {
      dispatch(resetInvitationListFilters());
    };
  }, []);

  const onClientSelect = (text) => {
    const clientId: any = clientList.find(
      (clientItem) => clientItem.firstName === text
    )?.id;

    clientId &&
      setSelectedClient({
        id: clientId,
        value: text,
      });

    getInvitationList({
      selectedPage: 1,
      selectedPageSize,
      searchValue: clientId,
      selectedDate,
      status,
    });
  };

  const handleOnClientNameChange = async (e, isSelectClick) => {
    const text = e.target.value;
    setClientName(e.target.value);
    setClientSelectionError("");

    e.isSelectClick && onClientSelect(text);

    if (!text || text?.length < 3) {
      setSelectedClient({
        id: "",
        value: "",
      });

      return false;
    }

    const res = await searchClients({
      selectedPage: 1,
      selectedPageSize: 10,
      searchValue: text,
    });

    if (res.success) {
      setClientList(res.data);
    }
  };

  const handlePageFilters = ({ selectedPage, selectedPageSize }) => {
    getInvitationList({
      selectedPage,
      selectedPageSize,
      searchValue,
      selectedDate,
      status,
    });
  };

  const handleStatusFilterChange = (e) => {
    getInvitationList({
      selectedPage: 1,
      selectedPageSize,
      searchValue,
      selectedDate,
      status: e.target.value,
    });
  };

  const filterStatusValue = (status) => {
    const statusValues = Object.keys(CONST_PROJECT_STATUS_LIST)
      ?.map((r) =>
        status.includes(r) ? CONST_PROJECT_STATUS_LIST[r].displayLabel : null
      )
      .filter(Boolean)
      ?.join(", ");
    return statusValues;
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Grid container alignItems={"center"} justifyContent={"space-between"}>
        <Grid item xs={12} sm={8} container spacing={2} mb={2}>
          <Grid item xs={12} sm={6} md={4}>
            <AutoSelectbox
              list={clientList}
              inlineLabel
              listProperties={{
                field: "firstName",
                value: "id",
              }}
              requireOnChange
              onChange={handleOnClientNameChange}
              label={"Select Client"}
              error={clientSelectionError}
              labelStyle={{
                fontSize: 16,
                fontWeight: 400,
                color: "#000000DE",
              }}
              textfieldStyle={{ height: 22 }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={selectedDate || null}
                componentsProps={{
                  actionBar: {
                    actions: ["clear"],
                  },
                }}
                onChange={(e) => {
                  const date = e ? new Date(e["$d"]).toString() : "";
                  getInvitationList({
                    searchValue: "",
                    selectedPage: 1,
                    selectedPageSize,
                    status,
                    selectedDate: date || "",
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "", // Remove the default placeholder
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                multiple
                fullWidth
                value={Array.isArray(status) ? status : [status]}
                onChange={handleStatusFilterChange}
                input={<OutlinedInput label="Status" />}
                renderValue={(selected: any) => filterStatusValue(selected)}
                MenuProps={MenuProps}
              >
                {statuses.map(({ displayLabel, value }) => (
                  <MenuItem key={displayLabel} value={value}>
                    <Checkbox checked={status.includes(value)} />
                    <ListItemText primary={displayLabel} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item>
          <ButtonWithIcon
            handleOnClick={onNewInvitationClick}
            text={"New Invitation".toUpperCase()}
            icon={{ position: "startIcon", name: "add" }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} direction="column">
        <Grid item xs={12}>
          <InvitationListTable
            list={invitationList}
            columns={INVITATION_LIST_COLUMNS}
            handlePageFilters={handlePageFilters}
            appliedFilters={{
              selectedPage,
              selectedPageSize,
              searchValue,
              selectedDate,
            }}
            onInvitationAceeptClick={onInvitationAceeptClick}
            onInvitationRejectClick={onInvitationRejectClick}
            onInvitationDeleteClick={onInvitationDeleteClick}
            onRescheduleInvitationClick={onRescheduleInvitationClick}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default invitationHOC(React.memo(InvitationList));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
