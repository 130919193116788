import {
  Grid,
  Typography,
  LinearProgress,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import FlexBox from "../layouts/flexbox";
import "./style.css";
import React from "react";

function VerticleProgressGraph({
  percentages = [],
  totalResponses = [],
  maxRating = 5,
}) {
  const columnWidth = Math.floor(12 / maxRating);

  return (
    <Grid container justifyContent={"space-evenly"} spacing={2}>
      {Array.from({ length: maxRating + 1 }, (_, i) => {
        const perc = percentages[i] || 0;
        return (
          <Grid
            key={i}
            item
            xs={columnWidth}
            display="flex"
            flexDirection="column"
            alignItems="center"
            height={300}
          >
            <Typography variant="caption">{perc}%</Typography>
            <Typography variant="caption">{totalResponses[i]}</Typography>
            <Typography variant="caption" color="textSecondary">
              resp.
            </Typography>

            <Box
              sx={{
                height: "100%",
                width: "100%",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  height: `${perc}%`,
                  width: "100%",
                  backgroundColor: "#2596be",
                  position: "absolute",
                  bottom: 0,
                }}
              />
              <Box
                sx={{
                  height: `${100 - perc}%`,
                  width: "100%",
                  backgroundColor: "lightblue",
                  position: "absolute",
                  bottom: `${perc}%`,
                }}
              />
            </Box>

            <Typography variant="caption">{i === 0 ? "Other" : i}</Typography>
          </Grid>
        );
      })}
    </Grid>
  );
}

function HorizontalProgressBar({ percentage = 0, label = "", responses = 0 }) {
  const progressColor = "#2596be";

  return (
    <Box sx={{ width: "100%", margin: "auto" }}>
      <FlexBox justifyContent={"space-between"}>
        <Typography variant="caption">{label}</Typography>
        <FlexBox gap={1}>
          <Typography variant="caption" color="textSecondary">
            {responses} resp.
          </Typography>
          <Typography variant="caption">{percentage}%</Typography>
        </FlexBox>
      </FlexBox>
      <Box
        sx={{
          borderRadius: 1,
          overflow: "hidden",
        }}
      >
        <LinearProgress
          variant="determinate"
          value={percentage}
          sx={{
            height: 25,
            backgroundColor: "lightblue",
            "& .MuiLinearProgress-bar": {
              backgroundColor: progressColor,
            },
          }}
        />
      </Box>
    </Box>
  );
}

function TextInputResponse({ responses = [] }) {
  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "background.paper",
      }}
    >
      <List sx={{ width: "100%", bgcolor: "#F2F2F2", p: 0 }}>
        {responses.map((value) => (
          <React.Fragment key={`${value}`}>
            <ListItem key={value} disableGutters sx={{ px: 2 }}>
              <ListItemText
                primary={`${value ?? "None"}`}
                primaryTypographyProps={{ variant: "caption" }}
              />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
}

export { VerticleProgressGraph, HorizontalProgressBar, TextInputResponse };
