import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import MuiTabs from "../../../components/mui/tabs";
import ClientsInfo from "./info";
import ClientMeetings from "./meetings";
import ClientPayments from "./payments";
import ClientInvitationStatus from "./invitation-status";
import ClientAmbassadors from "./ambassadors";
import ClientCampaigns from "./campaign";
import clientHOC from "../client-hoc";
import { useDispatch } from "react-redux";
import {
  resetSelectedClient,
  resetClientInfo,
} from "../../../redux/modules/clients/actions";
import { convertToFullName, convertToUrl } from "../../../services/utils";
import ClientManualMeeting from "./manual-meeting";
import PreQualifyQuestions from "./prequalify-questions";
import { ROUTE_PATHS } from "../../../routes/route-paths";
import VendorReviewsList from "./reviews";

function ClientDetails({
  clientDetails,
  getClientDetails,
  setBreadcrumbLabelCustomText,
}) {
  const { id, tab }: any = useParams();
  const [selectedTab, setSelectedTab] = useState(tab);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    getClientDetails(id);
    return () => {
      dispatch(resetSelectedClient());
      dispatch(resetClientInfo());
    };
  }, []);

  useEffect(() => {
    setBreadcrumbLabelCustomText(
      convertToFullName(clientDetails?.firstName, clientDetails?.lastName)
    );
  }, [clientDetails]);

  const renderTabItems = () => {
    switch (selectedTab) {
      case "info":
        return <ClientsInfo />;
      case "meetings":
        return <ClientMeetings />;
      case "payments":
        return <ClientPayments />;
      case "invitation_status":
        return <ClientInvitationStatus />;
      case "client_ambassadors":
        return <ClientAmbassadors />;
      case "campaign":
        return <ClientCampaigns />;
      case "manual_meeting":
        return <ClientManualMeeting />;
      case "prequalify_questions":
        return <PreQualifyQuestions questions={clientDetails?.questions} />;
      case "reviews":
        return <VendorReviewsList />;
      default:
        break;
    }
  };

  const handleTabChange = (value) => {
    setSelectedTab(value);
    const url = convertToUrl(ROUTE_PATHS.CLIENTS.DETAILS, {
      ":id": id,
      ":tab": value,
    });

    window && window.history.replaceState(null, "", url);
  };

  if (!clientDetails) return null;

  return (
    <Box display={"flex"} flexDirection="column">
      <MuiTabs
        items={[
          { value: "info", label: "Info" },
          { value: "invitation_status", label: "Invitation Status" },
          { value: "meetings", label: "Meetings" },
          { value: "payments", label: "Payments" },
          { value: "client_ambassadors", label: "Ambassadors" },
          { value: "campaign", label: "Campaign" },
          { value: "manual_meeting", label: "Manual Meeting" },
          { value: "prequalify_questions", label: "Pre Qualify Questions" },
          { value: "reviews", label: "Reviews" },
        ]}
        selectedTab={tab}
        onTabChange={handleTabChange}
      />
      {renderTabItems()}
    </Box>
  );
}

export default clientHOC(ClientDetails);
