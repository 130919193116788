import FlexBox from "../layouts/flexbox";
import { Typography } from "@mui/material";
import {
  HorizontalProgressBar,
  TextInputResponse,
  VerticleProgressGraph,
} from "./ResponseTypeRenderer";
import React from "react";

function MultiSelectQuestion({ options = [] }) {
  return (
    <FlexBox flexDirection={"column"}>
      {options?.map((option: any, index) => (
        <FlexBox my={2} key={`MultiSelectQuestion-${index}`}>
          <HorizontalProgressBar
            percentage={option?.percentage}
            label={option?.answer}
            responses={option?.count}
          />
        </FlexBox>
      ))}
    </FlexBox>
  );
}

function RatingSelectQuestion({
  avgRating,
  percentages = [],
  totalResponses = [],
  maxRating = 5,
}) {
  return (
    <React.Fragment>
      <Typography variant="h6" mb={4}>
        {avgRating} Average rating
      </Typography>
      <VerticleProgressGraph
        percentages={percentages}
        totalResponses={totalResponses}
        maxRating={maxRating}
      />
    </React.Fragment>
  );
}

function TextFieldInputQuestion({ responses = [] }) {
  return <TextInputResponse responses={responses} />;
}

export default function TypeformQuestionResponse({
  question,
  totalResponses,
  totalFeedbacks,
  type,
  data,
}) {
  const renderAnswerReport = () => {
    switch (type) {
      case "rating":
        return <RatingSelectQuestion {...data} />;
      case "radio":
      case "multi_select":
        return <MultiSelectQuestion {...data} />;

      default:
        return <TextFieldInputQuestion {...data} />;
    }
  };

  return (
    <FlexBox my={4} flexDirection={"column"}>
      <FlexBox mb={5} flexDirection={"column"}>
        <Typography variant="caption">{question}</Typography>
        <Typography variant="caption" color={"textSecondary"}>
          {totalResponses} out of {totalFeedbacks} answered
        </Typography>
      </FlexBox>
      {renderAnswerReport()}
    </FlexBox>
  );
}
