import { MODULE_ICON_MAP } from "../../../constants/moduleicon";
import { ROUTE_PATHS } from "../../../routes/route-paths";

const APP_BREADCRUMB = {
  [ROUTE_PATHS.CONNECT_REQUESTS]: {
    isRoot: true,
    paths: [{ label: "Connect", icon: MODULE_ICON_MAP.CONNECT }],
    title: "Connect",
  },
  [ROUTE_PATHS.OPPORTUNITY]: {
    isRoot: true,
    paths: [{ label: "Opportunity", icon: MODULE_ICON_MAP.OPPORTUNITY }],
    title: "Opportunity",
  },
  [ROUTE_PATHS.REVIEWS]: {
    isRoot: true,
    paths: [{ label: "Reviews", icon: MODULE_ICON_MAP.REVIEWS }],
    title: "Reviews",
  },
};

export { APP_BREADCRUMB };
