import React, { useState } from "react";
import FlexBox from "../../components/layouts/flexbox";
import { Divider, IconButton, InputBase, Paper, Skeleton } from "@mui/material";
import { IMAGES_PATH } from "../../constants";
import { BootstrapTooltip } from "../../components/mui/BootstrapTooltip";
import LinkIcon from "@mui/icons-material/Link";

export function ReviewReportSkeleton() {
  return (
    <React.Fragment>
      <FlexBox mb={2}>
        <FlexBox flexDirection={"column"}>
          <Skeleton variant="text" width={200} height={40} />
          <Skeleton variant="text" width={100} height={20} />
        </FlexBox>
      </FlexBox>
      <Divider />
      <Skeleton variant="rectangular" width="100%" height={60} sx={{ my: 2 }} />
      <Skeleton
        variant="rectangular"
        width="100%"
        height={200}
        sx={{ my: 2 }}
      />
      <Divider />
      <Skeleton variant="rectangular" width="100%" height={60} sx={{ my: 2 }} />
      <Skeleton
        variant="rectangular"
        width="100%"
        height={200}
        sx={{ my: 2 }}
      />
      <Divider />
      <Skeleton variant="rectangular" width="100%" height={60} sx={{ my: 2 }} />
      <Skeleton
        variant="rectangular"
        width="100%"
        height={200}
        sx={{ my: 2 }}
      />
    </React.Fragment>
  );
}

export function ErrorMessage() {
  return (
    <section className="custom-form-section">
      <div className="msg-wrapper">
        <div className="msg-block already-submit-msg">
          <img
            src={`${IMAGES_PATH}/something-went-wrong.jpg`}
            height={50}
            width={50}
          />
          <h2 style={{ color: "red", fontWeight: 500 }}>
            Oops! Something went wrong. Please try again later.
          </h2>
        </div>
      </div>
    </section>
  );
}

export function FeedbackReportLink({ url }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleCopyToClipboard = () => {
    setTooltipOpen(true);
    navigator.clipboard.writeText(url);

    setTimeout(() => {
      setTooltipOpen(false);
    }, 2000);
  };

  return (
    <Paper
      elevation={1}
      component="form"
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: 45,
      }}
    >
      <InputBase
        sx={{ ml: 1, pl: 0.5, flex: 1 }}
        size="small"
        fullWidth
        placeholder="Copy to clipboard"
        inputProps={{
          "aria-label": "Copy to clipboard",
          sx: { fontSize: 13 },
        }}
        readOnly
        value={url}
      />
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <BootstrapTooltip
        title="Copied to clipboard"
        open={tooltipOpen}
        disableFocusListener
        disableHoverListener
        placement="top"
        arrow
        disableTouchListener
      >
        <IconButton
          color="primary"
          sx={{ p: "10px" }}
          aria-label="directions"
          onClick={handleCopyToClipboard}
        >
          <LinkIcon sx={{ color: "#2e80ec", transform: "rotate(-45deg)" }} />
        </IconButton>
      </BootstrapTooltip>
    </Paper>
  );
}
