import { getGridCol } from "../utils";

export const ShareContactFormConfig = {
  horizontalFormWithInlineLabel: true,
  formFields: {
    email: "",
    title: "",
    firstName: "",
    lastName: "",
    linkedIn: "",
  },
  formFieldConfig: {
    isValid: false,
    resetAfterSubmit: true,
    fields: {
      email: {
        label: "Email",
        variant: "outlined",
        width: {
          rootGrid: {
            sm: 12,
            md: 12,
            lg: 12,
          },
        },
      },
      title: {
        label: "Title",
        variant: "outlined",
        width: {
          rootGrid: {
            sm: 12,
            md: 12,
            lg: 12,
          },
        },
      },
      firstName: {
        label: "First Name",
        variant: "outlined",
        width: {
          rootGrid: {
            sm: 6,
            md: 6,
            lg: 6,
          },
        },
      },
      lastName: {
        label: "Last Name",
        variant: "outlined",
        width: {
          rootGrid: {
            sm: 6,
            md: 6,
            lg: 6,
          },
        },
      },
      linkedIn: {
        label: "LinkedIn",
        variant: "outlined",
        width: {
          rootGrid: {
            sm: 12,
            md: 12,
            lg: 12,
          },
        },
      },
    },
  },
};

export default {
  horizontalFormWithInlineLabel: true,
  formFields: {
    name: "",
    description: "",
    country: "",
    state: "",
    address: "",
    categories: "",
    keywords: "",
    website: "",
    no_of_emp: "",
    revenue: "",
    funding: "",
    fundYear: "",
    founded: "",
    linkedIn: "",
    pricing: "",
    pricingStructure: "",
    certification: "",
    contact: "",
    personal: "",
    interestedId: "",
    demoLink: "",
  },
  formFieldConfig: {
    isValid: false,
    fields: {
      name: {
        label: "Company Name",
      },
      description: {
        label: "Company Overview",
        type: "textarea",
      },
      address: {
        label: "Company Address",
        type: "textarea",
      },
      country: {
        label: "Country",
        type: "autocomplete_single_select",
        valueKey: "country",
        labelKey: "country",
        list_key: "countries",
      },
      state: {
        label: "State",
        type: "autocomplete_single_select",
        valueKey: "name",
        labelKey: "name",
        list_key: "country_state",
        required: false,
      },
      categories: {
        label: "Categories",
        type: "select",
        labelKey: "category",
        valueKey: "category",
        list_key: "mp_categories",
      },
      keywords: {
        label: "Keywords",
        type: "async_input_searchbox"
      },
      website: {
        label: "Website",
      },
      demoLink: {
        label: "demoLink",
        required: false,
      },
      no_of_emp: {
        label: "Number of Employees",
        list_key: "noOfEmp",
        valueKey: "id",
        labelKey: "label",
        type: "select",
      },
      revenue: {
        label: "Estimated Revenues (in millions)",
        type: "amount",
        required: false,
      },
      funding: {
        label: "Total Funding Amount (in millions)",
        type: "amount",
        required: false,
      },
      founded: {
        label: "Founded",
        flatList: true,
        type: "select",
        list_key: "mp_year",
      },
      fundYear: {
        label: "Fund Year",
        flatList: true,
        required: false,
        type: "select",
        list_key: "mp_year",
      },
      linkedIn: {
        label: "LinkedIn URL",
      },
      pricing: {
        label: "Pricing",
        required: false,
        type: "multi_select",
        list_key: "mp_pricing",
        labelKey: "label",
        valueKey: "label",
      },
      pricingStructure: {
        label: "Pricing Structures (e.g., per seat, per data)",
        required: false,
        freeSolo: true,
        type: "multi_select_autocomplete_chip_textfield",
      },
      certification: {
        label: "Certifications",
        required: false,
        flatList: true,
        type: "multi_select_autocomplete_chip_textfield",
        list_key: "mp_certifications",
        placeholder: "e.g., SOC2, ISO, FedRamp, etc. – Type and Press Enter",
      },
      contact: {
        label: "Contact Information For NDA (Provide business email)",
        required: false,
      },
      personal: {
        label:
          "Personal Gmail Account Try Out A Demo Or Trial Instance In The Cloud",
        required: false,
      },
      interestedId: {
        label: "What Are You Most Interested In?",
        type: "multi_select",
        labelKey: "label",
        valueKey: "label",
        list_key: "lookingFor",
        required: false,
      },
    },
  },
};

export const inputFields = [
  { name: "name", size: `col-${getGridCol({ xs: 12, sm: 6, md: 6 })}` },
  { name: "address", size: `col-${getGridCol({ xs: 12, sm: 6, md: 6 })}` },
  { name: "description", size: `col-${getGridCol({ xs: 12, sm: 6, md: 6 })}` },
  { name: "country", size: `col-${getGridCol({ xs: 12, sm: 6, md: 6 })}` },
  { name: "state", size: `col-${getGridCol({ xs: 12, sm: 6, md: 6 })}` },
  { name: "categories", size: `col-${getGridCol({ xs: 12, sm: 12, md: 12 })}` },
  { name: "keywords", size: `col-${getGridCol({ xs: 12, sm: 12, md: 12 })}` },
  { name: "website", size: `col-${getGridCol({ xs: 12, sm: 6, md: 6 })}` },
  { name: "no_of_emp", size: `col-${getGridCol({ xs: 12, sm: 6, md: 6 })}` },
  { name: "revenue", size: `col-${getGridCol({ xs: 12, sm: 6, md: 6 })}` },
  { name: "funding", size: `col-${getGridCol({ xs: 12, sm: 6, md: 6 })}` },
  { name: "founded", size: `col-${getGridCol({ xs: 12, sm: 6, md: 6 })}` },
  { name: "fundYear", size: `col-${getGridCol({ xs: 12, sm: 6, md: 6 })}` },
  { name: "linkedIn", size: `col-${getGridCol({ xs: 12, sm: 12, md: 12 })}` },
  { name: "certification", size: `col-${getGridCol({ xs: 12, sm: 12, md: 12 })}` },
  { name: "pricing", size: `col-${getGridCol({ xs: 12, sm: 6, md: 6 })}` },
  { name: "contact", size: `col-${getGridCol({ xs: 12, sm: 6, md: 6 })}` },
  { name: "pricingStructure", size: `col-${getGridCol({ xs: 12, sm: 12, md: 12 })}` },
  { name: "interestedId", size: `col-${getGridCol({ xs: 12, sm: 12, md: 12 })}` },
];

export const fileUploadFields = [
  {
    label: "Implementation Steps/Timeline - (DOC, PDF)",
    name: "onboardingGuide",
  },
  {
    label: "Details On Requirements For A Pilot/POC - (DOC, PDF)",
    name: "permissions",
  },
  {
    label:
      "Standard Or Proposed Contract/Licensing/Subscription Agreement - (DOC, PDF)",
    name: "agreement",
  },
  {
    label:
      "Technical Documentation Access: Providing Access To Technical Documentation - (DOC, PDF)",
    name: "technicalDocs",
  },
  { label: "Detailed Use Case Examples - (DOC, PDF)", name: "useCase" },
];
