import {
  deleteApi,
  getApi,
  patchtApi,
  postApi,
  putApi,
} from "../services/axios";
import APIs from ".";
import {
  setClienInvitationStatusList,
  setClientCampaignsList,
  setClientCampaignsListLoading,
  setClientInvitationStatusFilters,
  setClientInvitationStatusListLoading,
  setClientListFilters,
  setClientListLoading,
  setClientMeetingsFilters,
  setClientMeetinsList,
  setClientMeetinsListLoading,
  setClientPaymentsList,
  setClientPaymentsListLoading,
  setClientsList,
  setSelectedClient,
} from "../redux/modules/clients/actions";
import { DATE_FORMATS, formatDate } from "../services/date-and-time.utils";

export function getClients({
  selectedPage = 1,
  selectedPageSize,
  searchValue = "",
}) {
  return async function (dispatch) {
    const res = await getApi(
      APIs.CLIENTS.GET_CLIENTS({ selectedPage, selectedPageSize, searchValue }),
      {
        validateUser: true,
        loadingFn: setClientListLoading,
      }
    );

    if (res.success) {
      dispatch(
        setClientsList({
          data: res.data,
          total: res.total,
        })
      );

      dispatch(
        setClientListFilters({
          selectedPage,
          selectedPageSize,
          searchValue,
        })
      );
    }

    return res;
  };
}

export function getClientMeetings({
  selectedPage,
  selectedPageSize,
  clientId,
}) {
  return async function (dispatch) {
    const res = await getApi(
      APIs.CLIENTS.GET_CLIENT_MEETINGS({
        selectedPage,
        selectedPageSize,
        clientId,
      }),
      {
        validateUser: true,
        loadingFn: setClientMeetinsListLoading,
      }
    );

    if (res.success) {
      dispatch(
        setClientMeetinsList({
          data: res.data,
          total: res.total,
        })
      );

      dispatch(
        setClientMeetingsFilters({
          selectedPage,
          selectedPageSize,
        })
      );
    }

    return res;
  };
}

export function getClientInvitationStatus({ clientId, selectedDate }) {
  return async function (dispatch) {
    const res = await getApi(
      APIs.CLIENTS.GET_CLIENT_INVITATION_STATUS({
        clientId,
        selectedDate,
      }),
      {
        validateUser: true,
        loadingFn: setClientInvitationStatusListLoading,
      }
    );

    if (res.success) {
      dispatch(
        setClienInvitationStatusList({
          data: res.data,
          total: res.total,
        })
      );

      dispatch(
        setClientInvitationStatusFilters({
          selectedDate,
        })
      );
    }

    return res;
  };
}

export function getClientPayments({ clientId }) {
  return async function (dispatch) {
    const res = await getApi(
      APIs.CLIENTS.GET_CLIENT_PAYMENTS({
        clientId,
      }),
      {
        validateUser: true,
        loadingFn: setClientPaymentsListLoading,
      }
    );

    if (res.success) {
      dispatch(
        setClientPaymentsList({
          ...res.data,
        })
      );
    }

    return res;
  };
}

export function getClientCampaignsList({ clientId }) {
  return async function (dispatch) {
    const res = await getApi(
      APIs.CLIENTS.GET_CLIENT_CAMPAIGNS({
        clientId,
      }),
      {
        validateUser: true,
        loadingFn: setClientCampaignsListLoading,
      }
    );

    if (res.success) {
      dispatch(
        setClientCampaignsList({
          data: res?.data,
          total: res?.data?.length,
        })
      );
    }

    return res;
  };
}

export function getClientDetails(id) {
  return async function (dispatch, getState) {
    const res = await getApi(APIs.CLIENTS.GET_CLIENT_DETAILS(id), {
      validateUser: true,
    });

    if (res.success) {
      dispatch(setSelectedClient(res.data));
    }

    return res;
  };
}

export async function deleteClient(id) {
  const res = await deleteApi(APIs.CLIENTS.DELETE_CLIENT(id), {
    validateUser: true,
  });

  return res;
}

export async function addClient(payload) {
  const res = await postApi(APIs.CLIENTS.ADD_CLIENT, payload, {
    validateUser: true,
  });

  if (res.success) {
  }

  return res;
}

export async function updateClient(id, payload) {
  const res = await putApi(APIs.CLIENTS.UPDATE_CLIENT(id), payload, {
    validateUser: true,
  });

  if (res.success) {
  }

  return res;
}

export async function searchClients({
  selectedPage,
  selectedPageSize,
  searchValue,
}) {
  const res = await getApi(
    APIs.CLIENTS.GET_CLIENTS({ selectedPage, selectedPageSize, searchValue }),
    {
      validateUser: true,
      loadingFn: setClientListLoading,
    }
  );

  return res;
}

export async function searchVendorCompanyWithoutDelegate({
  selectedPage,
  selectedPageSize,
  searchValue,
}) {
  const res = await getApi(
    APIs.CLIENTS.GET_COMPANY_NAME_WITHOUT_DELEGATE({
      selectedPage,
      selectedPageSize,
      searchValue,
    }),
    {
      validateUser: true,
      loadingFn: setClientListLoading,
    }
  );

  return res;
}

export async function getClientUpcomingMeetings({
  clientId,
  token,
  invitationId,
}) {
  const currentDate = formatDate(new Date(), DATE_FORMATS.yyymmdd);
  const res = await getApi(
    APIs.CLIENTS.GET_CLIENT_UPCOMING_MEETINGS({
      clientId,
      date: currentDate,
      token,
      invitationId,
    }),
    {
      validateUser: true,
    }
  );

  if (res.success) {
    return res?.data;
  }

  return res;
}

export function getAmbassadorListBasedOnCriteria(id) {
  return async function (dispatch) {
    const res = await getApi(
      APIs.CLIENTS.GET_AMBASSADOR_BASED_ON_CRITERIA(id),
      {
        validateUser: true,
        loadingFn: setClientListLoading,
      }
    );

    if (res.success) {
    }

    return res;
  };
}

export function getClientSelectedAmbassadorList(id, selectedOnly = false) {
  return async function (dispatch) {
    const res = await getApi(
      APIs.CLIENTS.GET_CLIENT_SELECTED_AMBASSADOR_(id, selectedOnly),
      {
        validateUser: true,
        loadingFn: setClientListLoading,
      }
    );

    if (res.success) {
    }

    return res;
  };
}

export function saveClientAmbasadorList(id, payload) {
  return async function (dispatch) {
    const res = await putApi(APIs.CLIENTS.UPDATE_CLIENT(id), payload, {
      validateUser: true,
    });

    if (res.success) {
      dispatch(getClientDetails(id));
    }

    return res;
  };
}

export function newBudgetSubmit(clientId, payload) {
  return async function (dispatch) {
    const res = await patchtApi(
      APIs.CLIENTS.SUBMIT_NEW_BUDGET(clientId),
      payload,
      {
        validateUser: true,
      }
    );

    if (res.success) {
      dispatch(getClientCampaignsList({ clientId }));
    }

    return res;
  };
}

export function submitManualMeeting(payload) {
  return async function (dispatch) {
    const res = await postApi(APIs.INVITATIONS.SUBMIT_MANUAL_MEETING, payload, {
      validateUser: true,
    });

    if (res.success) {
    }

    return res;
  };
}

export async function fetchClientMeetingData(id) {
  const res = await getApi(APIs.CLIENTS.GET_MEETING_SUMMARY(id), {
    validateUser: true,
  });

  if (res.success) {
  }

  return res;
}

export function transferRoleSubmit(payload) {
  return async function (dispatch) {
    const res = await postApi(APIs.INVITATIONS.POST_TRANSFER_ROLE, payload, {
      validateUser: true,
      notifyError: true,
    });

    if (res.success) {
    }

    return res;
  };
}
