import { Button, Grid, TextField } from "@mui/material";
import ReviewTable from "../components/review-table";
import React, { useEffect, useState } from "react";
import { fetchReviews } from "../../apis/typeform.api";
import { PaginationContext } from "../../components/mui/table/simple-table";
import Paginator from "../../components/mui/pagination";
import ReviewPageVendorSearch from "./vendor-search";
import { DEFAULT_SELECTED_PAGE_SIZE } from "../../constants";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import GenerateReviewReportModal from "./generate-report";
import { AppModalDialogBox } from "../../components/modals";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";

export default function Reviews() {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [columns, setColumns] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [pageSettings, setPageSettings] = useState({
    selectedPage: 1,
    selectedPageSize: DEFAULT_SELECTED_PAGE_SIZE,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [filters, setFilters] = useState({
    createdAt: "",
    clientId: "",
  });

  useEffect(() => {
    getReviews();
  }, []);

  const getReviews = async (payload = {}, searchParams = {}) => {
    try {
      setLoading(true);
      const params = {
        selectedPage: pageSettings.selectedPage,
        selectedPageSize: pageSettings.selectedPageSize,
        ...searchParams,
      };

      let reqPayload = {
        clientId: filters?.clientId,
        createdAt: filters?.createdAt,
        ...payload,
      };

      reqPayload = {
        ...(reqPayload?.clientId && { clientId: reqPayload?.clientId }),
        ...(reqPayload?.createdAt && { createdAt: reqPayload?.createdAt }),
      };

      const response: any = await fetchReviews(reqPayload, params);

      if (response?.success) {
        setReviews(response.data);
        setTotalRecords(response.total);
        if (columns?.length === 0) {
          setColumns(response.data?.[0]?.answers);
        }
      } else {
        setError("Failed to fetch reviews");
      }
    } catch (err) {
      setError("An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const onPageSizeChange = (newValue) => {
    setPageSettings({
      selectedPageSize: newValue,
      selectedPage: 1,
    });

    getReviews({}, { selectedPageSize: newValue, selectedPage: 1 });
  };

  const onPageChange = (newValue) => {
    setPageSettings({
      selectedPageSize: pageSettings.selectedPageSize,
      selectedPage: newValue,
    });

    getReviews(
      {},
      {
        selectedPageSize: pageSettings.selectedPageSize,
        selectedPage: newValue,
      }
    );
  };

  const handleDateFilterChange = (e) => {
    const date = e ? new Date(e["$d"]).toString() : "";
    setSelectedDate(e);

    setFilters({ ...filters, createdAt: date });

    getReviews(
      { createdAt: date, clientId: filters?.clientId },
      {
        selectedPageSize: pageSettings.selectedPageSize,
        selectedPage: 1,
      }
    );
  };

  const handleClientChange = (event, value) => {
    const clientId = value?.id;

    setFilters({ ...filters, clientId: clientId });

    getReviews(
      { clientId: clientId, createdAt: filters?.createdAt },
      {
        selectedPageSize: pageSettings.selectedPageSize,
        selectedPage: 1,
      }
    );
  };

  if (error) return <div>{error}</div>;

  return (
    <Grid container spacing={2} mt={5}>
      <Grid item xs={12} sm={6} md={1.7}>
        <label style={{ fontSize: 14 }}>Date</label>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={selectedDate}
            componentsProps={{
              actionBar: {
                actions: ["clear"],
              },
            }}
            onChange={handleDateFilterChange}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  placeholder: "", // Remove the default placeholder
                }}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <label style={{ fontSize: 14 }}>Search</label>
        <ReviewPageVendorSearch onChange={handleClientChange} />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        container
        alignItems={"center"}
        justifyContent={"flex-end"}
      >
        <GenerateReportButton />
      </Grid>
      <Grid item xs={12}>
        <ReviewTable
          reviews={reviews}
          ratingColumns={columns}
          loading={loading}
        />
      </Grid>
      {totalRecords > 0 && (
        <Grid item xs={12}>
          <PaginationContext.Provider
            value={{
              onPageChange,
              onPageSizeChange,
              totalRecords,
              showPageSize: true,
              selectedPageSize: pageSettings.selectedPageSize,
              selectedPage: pageSettings.selectedPage,
            }}
          >
            <Paginator />
          </PaginationContext.Provider>
        </Grid>
      )}
    </Grid>
  );
}

export function GenerateReportButton() {
  const [showReportModal, setShowReportModal] = React.useState(false);

  const handleGenerateReportClick = () => {
    setShowReportModal(true);
  };

  const handleClose = () => {
    setShowReportModal(false);
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        startIcon={<SummarizeOutlinedIcon />}
        onClick={handleGenerateReportClick}
        sx={{ mt: { sm: 3 } }}
      >
        Generate Report
      </Button>
      {showReportModal && (
        <AppModalDialogBox
          title={"Generate"}
          isModalOpen={true}
          handleClose={handleClose}
        >
          <GenerateReviewReportModal />
        </AppModalDialogBox>
      )}
    </React.Fragment>
  );
}
