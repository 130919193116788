import React from "react";
import { connect } from "react-redux";
import { ENUM_USER_ROLES } from "../../enums";
import { withRouter } from "react-router";
import { AdminPanelSidebar } from "../../components/layouts/sidebars";
import CssBaseline from "@mui/material/CssBaseline";
import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import { AppBreadcrumbs } from "../../components/layouts/breadcrumbs";
import { AuthActions } from "../../components/layouts/header/header-actions/auth-actions";
import { ROUTE_PATHS } from "../../routes/route-paths";
import { BREADCRUMBS } from "../../components/layouts/breadcrumbs/breadcrumb-paths";
import Container from "@mui/material/Container";
import FlexBox from "../../components/layouts/flexbox";
import { drawerWidth } from "../../constants";
import { isDesktop, isMobile } from "react-device-detect";
import { MODULE_ICON_MAP } from "../../constants/moduleicon";
import MenuIcon from "@mui/icons-material/Menu";

const SIDEBAR_MENU_ITEMS = [
  {
    label: "DASHBORD",
    icon: MODULE_ICON_MAP.DASHBOARD,
    path: ROUTE_PATHS.DASHBOARD,
    rootPath: ROUTE_PATHS.DASHBOARD,
  },
  {
    label: "CLIENTS",
    icon: MODULE_ICON_MAP.CLIENTS,
    path: ROUTE_PATHS.CLIENTS.LIST,
    rootPath: ROUTE_PATHS.CLIENTS.ROOT,
  },
  {
    label: "AMBASSADORS",
    icon: MODULE_ICON_MAP.AMBASSADORS,
    path: ROUTE_PATHS.AMBASSADORS.LIST,
    rootPath: ROUTE_PATHS.AMBASSADORS.ROOT,
  },
  {
    label: "INVITATIONS",
    icon: MODULE_ICON_MAP.INVITATIONS,
    path: ROUTE_PATHS.INVITATIONS.LIST,
    rootPath: ROUTE_PATHS.INVITATIONS.ROOT,
  },
  {
    label: "CONNECT",
    icon: MODULE_ICON_MAP.CONNECT,
    path: ROUTE_PATHS.CONNECT_REQUESTS,
    rootPath: ROUTE_PATHS.CONNECT_REQUESTS,
  },
  {
    label: "OPPORTUNITY",
    icon: MODULE_ICON_MAP.OPPORTUNITY,
    path: ROUTE_PATHS.OPPORTUNITY,
    rootPath: ROUTE_PATHS.OPPORTUNITY,
  },
  {
    label: "MARKETPLACE",
    icon: MODULE_ICON_MAP.MARKETPLACE,
    path: ROUTE_PATHS.MARKETPLACE.LIST,
    rootPath: ROUTE_PATHS.MARKETPLACE.ROOT,
  },
  {
    label: "REVIEWS",
    icon: MODULE_ICON_MAP.REVIEWS,
    path: ROUTE_PATHS.REVIEWS,
    rootPath: ROUTE_PATHS.REVIEWS,
  },
];

const adminLayoutHOC = (ComposedComponent) => {
  class AdminLayoutHOC extends React.Component<any, any> {
    constructor(props) {
      super(props);
      this.state = {
        customBreadcrumbLabel: "",
        drawerOpen: isMobile ? false : true,
      };
    }

    handleDrawerToggle = () => {
      this.setState({
        drawerOpen: !this.state.drawerOpen,
      });
    };

    render() {
      const { user, match } = this.props;

      const currentRoutePaths = BREADCRUMBS[match.path];

      const selectedSidebarMenuItem = SIDEBAR_MENU_ITEMS.find((sidebarItem) => {
        return match.path.search(sidebarItem.rootPath) !== -1;
      });

      if (!user.isLoggedIn || user.role === ENUM_USER_ROLES.USER) {
        this.props.history.push(ROUTE_PATHS.UNAUTHORIZED);
        return null;
      } else
        return (
          <FlexBox minHeight={"100vh"} position={"relative"}>
            <CssBaseline />

            <AdminPanelSidebar
              selected={selectedSidebarMenuItem}
              list={SIDEBAR_MENU_ITEMS}
              open={this.state.drawerOpen}
              onToggle={this.handleDrawerToggle}
            />
            {!isDesktop && (
              <AppBar
                position="fixed"
                sx={{
                  boxShadow: 0,
                  bgcolor: "#070639",
                  backgroundImage: "none",
                  top: "var(--template-frame-height, 0px)",
                }}
              >
                <Toolbar variant="regular">
                  {!isDesktop && (
                    <IconButton onClick={this.handleDrawerToggle}>
                      <MenuIcon sx={{ color: "white" }} />
                    </IconButton>
                  )}
                </Toolbar>
              </AppBar>
            )}
            <FlexBox
              component="main"
              sx={{
                transition: (theme) =>
                  theme.transitions.create("margin-left", {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                  }),
                overflowY: "auto",
              }}
              flexDirection={"column"}
              flexGrow={1}
              marginLeft={isDesktop ? `${drawerWidth}px` : 0}
            >
              <FlexBox
                flexDirection={"column"}
                minHeight="100vh"
                py={{ xs: 9, md: 8 }}
              >
                <Container
                  maxWidth="xl"
                  component={Box}
                  flexDirection={"column"}
                >
                  <Box
                    component={"div"}
                    justifyContent="space-between"
                    alignItems={"center"}
                    display={"flex"}
                    sx={{ mb: 5 }}
                  >
                    <AppBreadcrumbs
                      currentRoutePaths={currentRoutePaths?.paths}
                      customLabel={this.state.customBreadcrumbLabel}
                    />

                    <AuthActions />
                  </Box>

                  <ComposedComponent
                    {...this.props}
                    setBreadcrumbLabelCustomText={(text) => {
                      this.setState({
                        customBreadcrumbLabel: text,
                      });
                    }}
                  />
                </Container>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        );
    }
  }

  const mapStateToProps = (state) => {
    return {
      user: state.auth.user,
    };
  };

  return connect(mapStateToProps, null)(withRouter(AdminLayoutHOC));
};

export default adminLayoutHOC;
