import { IconButton, Typography } from "@mui/material";
import { Image } from "../../../components/images";
import { SimpleTable } from "../../../components/mui/table";
import { RENDERER_TYPES } from "../../../enums";
import {
  DATE_FORMATS,
  formatDate,
} from "../../../services/date-and-time.utils";
import { convertToFullName } from "../../../services/utils";
import SvgIcons from "../../../svgs";
import { MeetingStatus } from "../components/meeting-status";
import PendingActionsTwoToneIcon from "@mui/icons-material/PendingActionsTwoTone";
import { AppChip } from "../../../components/chips";
import { useState } from "react";
import { ProjectStatus } from "../components/project-status";

function InvitationListTable({
  list,
  columns,
  handlePageFilters,
  appliedFilters,
  onInvitationAceeptClick,
  onInvitationRejectClick,
  onInvitationDeleteClick,
  onRescheduleInvitationClick,
}) {
  const { selectedPage, selectedPageSize } = appliedFilters;
  const handleTDRenderer = (row, column) => {
    switch (column.rendererType) {
      case RENDERER_TYPES.FULL_NAME:
        if (column.field === "ambassador") {
          return (
            <>
              <Typography fontSize={13} fontWeight="400" color="#6C7293">
                {convertToFullName(
                  row.ambassador.firstName,
                  row.ambassador.lastName
                )}
              </Typography>
              <Typography fontSize={12} fontWeight="400" color="#A7ABC3">{`${
                row.ambassador.title || ""
              }`}</Typography>
            </>
          );
        }
        if (column.field === "client") {
          return (
            <>
              <Typography fontSize={13} fontWeight="400" color="#6C7293">
                {convertToFullName(row.client.firstName, row.client.lastName)}
              </Typography>
              <Typography fontSize={12} fontWeight="400" color="#A7ABC3">{`${
                row.client.email || ""
              }`}</Typography>
              <Typography
                fontSize={12}
                mb={1}
                fontWeight="400"
                color="#A7ABC3"
              >{`${row.client.title || ""}`}</Typography>
              <ViewSecondaryMembers
                secondaryMembers={row?.client?.secondaryMembers}
              />
            </>
          );
        }
        return;
      case RENDERER_TYPES.DATE_RANGE:
        return `${formatDate(
          row.selectedTimeSlot.from,
          DATE_FORMATS.fullDateWithTime
        )} - ${formatDate(
          row.selectedTimeSlot.to,
          DATE_FORMATS.hourMinWithAMPM
        )}`;
      case RENDERER_TYPES.MEETING_STATUS:
        return <ProjectStatus data={row} labelField="displayLabel" />;
      case RENDERER_TYPES.ACTION:
        if (row.status === "done" || row.isRejected || row.status === "archive")
          return false;

        return (
          <>
            <IconButton
              title="Approve"
              onClick={() => onInvitationAceeptClick(row.id)}
            >
              <img src={SvgIcons.Frame24} />
            </IconButton>
            <IconButton
              title="Reject"
              onClick={() => onInvitationRejectClick(row.id)}
            >
              <img src={SvgIcons.Frame25} />
            </IconButton>
            <IconButton
              title="Reschedule"
              onClick={() => onRescheduleInvitationClick(row)}
            >
              <PendingActionsTwoToneIcon
                sx={{ width: "37.5px", height: "38.26px", color: "#5D78FF" }}
              />
            </IconButton>
          </>
        );
      default:
        break;
    }
  };

  return (
    <SimpleTable
      tdRenderer={handleTDRenderer}
      rows={list?.data}
      columns={columns}
      fetching={list?.loading}
      total={list?.total}
      onPageSettingsChange={handlePageFilters}
      selectedPage={selectedPage}
      selectedPageSize={selectedPageSize}
    ></SimpleTable>
  );
}

function ViewSecondaryMembers({ secondaryMembers = [] }) {
  const [show, setShow] = useState(false);

  if (!show)
    return (
      <Typography
        style={{ cursor: "pointer", textDecoration: "underline" }}
        variant="caption"
        onClick={() => setShow(!show)}
      >
        View Secondary Emails
      </Typography>
    );

  return (
    <>
      {secondaryMembers?.map((item) => (
        <AppChip
          key={item}
          bgColor="#F2F3F8"
          style={{ marginRight: 3, marginBottom: 3 }}
          label={item}
          fontColor={"#6C7293"}
        />
      ))}
    </>
  );
}

export { InvitationListTable };
