import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  getInvitations,
  submitInvitationApproval,
  updateInvitation,
} from "../../apis/invitations.api";
import { AppModalDialogBox } from "../../components/modals";
import { AppAlertBox } from "../../components/modals/alert-box";
import { DEFAULT_SELECTED_PAGE_SIZE, GLOBAL_TEXT } from "../../constants";
import { resetInvitationStore } from "../../redux/modules/invitations/actions";
import { ROUTE_PATHS } from "../../routes/route-paths";
import { RescheduleInvitationModal } from "./list/reschedule-invitation";
import { InvitationAcceptModal } from "./list/invitation-accept-modal";
import { CreateInvitationModal } from "./new-invitation/create-invite-modal";

const invitationHOC = (ComposedComponent) => {
  class InvitationHOC extends React.Component<any, any> {
    constructor(props) {
      super(props);

      this.state = {
        showInvitationRejectConfirm: false,
        showInvitationDeleteConfirm: false,
        showInvitationAcceptConfirm: false,
        showRescheduleInvitationModal: false,
        selectedId: null,
        selectedAmbassadors: [],
      };
    }

    componentWillUnmount(): void {
      if (
        !this.props.history.location.pathname.startsWith(
          ROUTE_PATHS.INVITATIONS.ROOT
        )
      ) {
        this.props.dispatchResetInvitationStore();
      }
    }

    handleNewInvitationClick = () => {
      const path = ROUTE_PATHS.INVITATIONS.NEW_INVITATION;

      this.props.history.push(path);
    };

    handleCreateInvitationClick = ({ ambassadors }) => {
      this.setState({
        ...this.state,
        showCreateInvitationModal: true,
        selectedAmbassadors: ambassadors,
      });
    };

    getInvitationList = ({
      selectedPage = 1,
      selectedPageSize = DEFAULT_SELECTED_PAGE_SIZE,
      searchValue = "",
      selectedDate = "",
      status = "",
    } = {}) => {
      this.props.dispatchGetInvitationList(
        selectedPage,
        selectedPageSize,
        searchValue,
        selectedDate,
        status
      );
    };

    handleOnInvitationAcceptClick = (id) => {
      this.setState({
        ...this.state,
        showInvitationAcceptConfirm: true,
        selectedId: id,
      });
    };

    handleOnInvitationAcceptConfirm = async (data) => {
      const payload = {
        id: this.state.selectedId,
        type: "done",
        data,
      };
      const invitationFilters = this.props.invitationFilters;

      await this.props.dispatchSubmitInvitationApproval(payload);

      this.handleModalClose();
      this.getInvitationList({ ...invitationFilters, selectedPage: 1 });
    };

    handleOnInvitationRejectClick = (id) => {
      this.setState({
        showInvitationRejectConfirm: true,
        selectedId: id,
      });
    };

    hanldeRescheduleInvitationClick = (row) => {
      this.setState({
        showRescheduleInvitationModal: true,
        selectedId: row.id,
        selectedRow: row,
      });
    };

    handleOnInvitationRejectConfirm = async ({ message }) => {
      const payload = {
        id: this.state.selectedId,
        type: "reject",
        data: { message },
      };
      const invitationFilters = this.props.invitationFilters;

      await this.props.dispatchSubmitInvitationApproval(payload);

      this.handleModalClose();

      this.getInvitationList({ ...invitationFilters, selectedPage: 1 });
    };

    handleOnInvitationDeleteClick = (id) => {
      this.setState({
        showInvitationDeleteConfirm: true,
        selectedId: id,
      });
    };

    handleOnInvitationDeleteConfirm = () => {};

    handleInvitationUpdate = async (payload) => {
      const response: any = await this.props.dispatchUpdateInvitation(payload);
      const invitationFilters = this.props.invitationFilters;

      if (response.success) {
        this.handleModalClose();
        this.getInvitationList({ ...invitationFilters, selectedPage: 1 });
      }
    };

    handleModalClose = () => {
      this.setState({
        showInvitationDeleteConfirm: false,
        showInvitationRejectConfirm: false,
        showInvitationAcceptConfirm: false,
        showCreateInvitationModal: false,
        showRescheduleInvitationModal: false,
        selectedId: null,
        selectedAmbassadors: null,
      });
    };

    render() {
      return (
        <>
          <ComposedComponent
            onNewInvitationClick={this.handleNewInvitationClick}
            getInvitationList={this.getInvitationList}
            invitationList={this.props.invitationList}
            onInvitationRejectClick={this.handleOnInvitationRejectClick}
            onInvitationDeleteClick={this.handleOnInvitationDeleteClick}
            onInvitationAceeptClick={this.handleOnInvitationAcceptClick}
            onCreateInvitationClick={this.handleCreateInvitationClick}
            onRescheduleInvitationClick={this.hanldeRescheduleInvitationClick}
            {...this.props}
          />

          <AppAlertBox
            isModalOpen={this.state.showInvitationRejectConfirm}
            onConfirm={this.handleOnInvitationRejectConfirm}
            onCancel={this.handleModalClose}
            message={GLOBAL_TEXT.ALERT_MESSAGES.CONFIRM_REJECT}
            withReason
            textFieldProps={{ rows: 5 }}
            validate
          />
          <AppAlertBox
            isModalOpen={this.state.showInvitationDeleteConfirm}
            onConfirm={this.handleOnInvitationDeleteConfirm}
            onCancel={this.handleModalClose}
            message={GLOBAL_TEXT.ALERT_MESSAGES.CONFIRM_DELETE}
          />

          <AppModalDialogBox
            isModalOpen={this.state.showInvitationAcceptConfirm}
            handleClose={this.handleModalClose}
            title={GLOBAL_TEXT.ALERT_MESSAGES.REQUEST_INVITATION_COMPLETE}
            size={"xs"}
          >
            <InvitationAcceptModal
              onAcceptSubmit={this.handleOnInvitationAcceptConfirm}
              onAcceptCancel={this.handleModalClose}
            />
          </AppModalDialogBox>

          {this.state.showCreateInvitationModal && (
            <CreateInvitationModal
              selectedAmbassadors={this.state.selectedAmbassadors}
              handleModalClose={this.handleModalClose}
            />
          )}
          {this.state.showRescheduleInvitationModal && (
            <RescheduleInvitationModal
              handleModalClose={this.handleModalClose}
              handleSubmit={this.handleInvitationUpdate}
              selectedValue={this.state.selectedRow.selectedTimeSlot}
              invitationId={this.state.selectedId}
              invitationData={this.state.selectedRow}
            />
          )}
        </>
      );
    }
  }
  const mapStateToProps = (state) => {
    return {
      invitationList: state.invitations.list,
      invitationFilters: state.invitations.list.filters,
    };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      dispatchGetInvitationList: (
        selectedPage,
        selectedPageSize,
        searchValue,
        selectedDate,
        status = ""
      ) =>
        dispatch(
          getInvitations({
            selectedPage,
            selectedPageSize,
            searchValue,
            selectedDate,
            status,
          })
        ),
      dispatchSubmitInvitationApproval: (payload) =>
        dispatch(submitInvitationApproval(payload)),
      dispatchResetInvitationStore: () => dispatch(resetInvitationStore()),
      dispatchUpdateInvitation: (payload) =>
        dispatch(updateInvitation(payload)),
    };
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(InvitationHOC));
};
export default invitationHOC;
