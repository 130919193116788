import { MODULE_ICON_MAP } from "../../../constants/moduleicon";
import { RENDERER_TYPES } from "../../../enums";

const DASHBOARD_SUMMARY = [
  {
    icon: MODULE_ICON_MAP.INVITATIONS,
    label: "New Meetings",
    rendererType: RENDERER_TYPES.NUMBER,
  },
  {
    icon: MODULE_ICON_MAP.AMBASSADORS,
    label: "New Ambassadors",
    rendererType: RENDERER_TYPES.NUMBER,
  },
  {
    icon: MODULE_ICON_MAP.CLIENTS,
    label: "New Clients",
    rendererType: RENDERER_TYPES.NUMBER,
  },
  {
    icon: MODULE_ICON_MAP.REVENUE,
    label: "New Revenue",
    rendererType: RENDERER_TYPES.AMOUNT,
  },
];

export { DASHBOARD_SUMMARY };
