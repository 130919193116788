import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CLIENT_LIST_COLUMNS } from "./table-config";
import { useStyles } from "./styles";
import { ButtonWithIcon } from "../../../components/buttons";
import { Box, Grid } from "@mui/material";
import clientHOC from "../client-hoc";
import Searchbox from "../../../components/searchbox";
import { ClientListTable } from "./tables";
import ViewersList from "../../opportunity/response-list/viewers-list-modal";
import FlexBox from "../../../components/layouts/flexbox";

function ClientsList({
  onAddClientClick,
  toggleMenuOptions,
  getClientList,
  clientList,
}: any) {
  const classes = useStyles();
  const { selectedPage, selectedPageSize, searchValue } = useSelector(
    (state: any) => state.clients.list.filters
  );
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [opportunityVendorId, setOpportunityVendorId] = useState(null);

  const handleShowDetailsClick = (row) => {
    setShowDetailsModal(true);
    setOpportunityVendorId(row?.id);
  };

  useEffect(() => {
    getClientList({ selectedPage, selectedPageSize, searchValue });
  }, []);

  const handlePageFilters = ({ selectedPage, selectedPageSize }) => {
    getClientList({
      selectedPage,
      selectedPageSize,
      searchValue,
    });
  };

  const handleModalClose = () => {
    setShowDetailsModal(false);
    setOpportunityVendorId(null);
  };

  return (
    <>
      <FlexBox alignItems={"center"} justifyContent={"space-between"} gap={2} mb={2}>
        <Searchbox
          onEnter={(value) =>
            getClientList({
              searchValue: value,
              selectedPage: 1,
              selectedPageSize,
            })
          }
          value={searchValue}
          onClear={() => {
            getClientList({
              searchValue: "",
              selectedPage: 1,
              selectedPageSize,
            });
          }}
        />

        <ButtonWithIcon
          handleOnClick={onAddClientClick}
          text={"ADD"}
          icon={{ position: "startIcon", name: "add" }}
        />
      </FlexBox>

      <Grid container spacing={2} direction="column">
        <Grid item>
          <ClientListTable
            list={clientList}
            toggleMenuOptions={toggleMenuOptions}
            columns={CLIENT_LIST_COLUMNS}
            onShowViewCountDetailsClick={handleShowDetailsClick}
            handlePageFilters={handlePageFilters}
            appliedFilters={{
              selectedPageSize,
              searchValue,
              selectedPage,
            }}
          />
        </Grid>
      </Grid>
      {showDetailsModal && (
        <ViewersList
          opportunityVendorId={opportunityVendorId}
          handleModalClose={handleModalClose}
        />
      )}
    </>
  );
}

export default clientHOC(React.memo(ClientsList));
