import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

export const BootstrapTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#000", // Dark background (Bootstrap-like)
    color: "#fff", // White text
    boxShadow: theme.shadows[1],
    fontSize: 12, // Adjusted font size (Bootstrap uses smaller text)
    borderRadius: 4, // Rounded corners
    padding: "8px 12px", // Padding to make it look Bootstrap-like
  },
}));
