import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

export default function MuiTabs({ items, selectedTab, onTabChange }) {
  const [value, setValue] = React.useState(selectedTab);

  React.useEffect(() => {
    setValue(selectedTab);
  }, [selectedTab]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    onTabChange(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        {items.map((item, index) => (
          <Tab
            key={index}
            sx={{
              color: item.value === value ? "#070639" : "#979797",
              fontWeight: 500,
              fontSize: 14,
            }}
            value={item.value}
            label={item.label}
          />
        ))}
      </Tabs>
    </Box>
  );
}
