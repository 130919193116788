import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { searchTypeformAmbassadorCompany } from "../../apis/typeform.api";

export default function ReviewPageAmbassadorSearch({ clientId, onChange }) {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [clientListLoading, setClientListLoading] = useState(false);

  useEffect(() => {
    getClientList(clientId);
  }, [clientId]);

  const getClientList = async (searchTerm: string) => {
    setClientListLoading(true);
    const response: any = await searchTypeformAmbassadorCompany(searchTerm);

    if (response?.success) {
      setOptions(response.data);
    }

    setClientListLoading(false);
  };

  return (
    <Autocomplete
      freeSolo
      size="small"
      options={options}
      getOptionLabel={(option) => `${option.company}- (${option.email})`}
      loading={clientListLoading}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Enter ambassador name"
          sx={{ fontSize: "14px" }}
          InputProps={{
            ...params.InputProps,
            sx: {
              height: "41px", // Increase the height
              padding: "0 14px", // Adjust padding as needed
            },
            endAdornment: <>{params.InputProps.endAdornment}</>,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}
