import { getApi, postApi } from "../services/axios";
import APIs from ".";

export async function fetchReviews(payload = {}, params = undefined) {
  if (!params) {
    const res = await postApi(APIs.TYPEFORM.GET_ALL_REVIEWS, payload, {
      validateUser: true,
    });

    return res;
  }

  const res = await postApi(APIs.TYPEFORM.GET_REVIEWS(params), payload, {
    validateUser: true,
  });

  return res;
}

export async function fetchReviewReportDetails(payload) {
  if (!payload?.clientId) {
    const res = await postApi(
      APIs.TYPEFORM.GET_REVIEW_REPORT_DETAILS,
      payload,
      {
        validateUser: true,
      }
    );
    return res;
  } else {
    const res = await postApi(
      APIs.TYPEFORM.GET_REVIEW_REPORT_DETAILS_BY_CLIENT(
        payload.clientId,
        payload?.ambassadorId
      ),
      payload,
      {
        validateUser: true,
      }
    );

    return res;
  }
}

export async function searchTypeformAmbassadorCompany(clientId) {
  const res = await getApi(APIs.TYPEFORM.GET_TYPEFORM_AMBASSADOR_COMPANY_NAME(clientId));

  return res;
}
