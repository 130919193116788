import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { fetchReviews } from "../../../../apis/typeform.api";
import { useParams } from "react-router";
import ReviewTable from "../../../components/review-table";

const VendorReviewsList = () => {
  const [reviews, setReviews] = useState([]);
  const [filteredReviews, setFilteredReviews] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { id }: any = useParams();

  useEffect(() => {
    getReviews();
  }, []);

  const getReviews = async (payload = {}) => {
    try {
      const response: any = await fetchReviews({
        clientId: id,
      });

      if (response?.success) {
        setReviews(response.data);
        setFilteredReviews(response.data);
      } else {
        setError("Failed to fetch reviews");
      }
    } catch (err) {
      setError("An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const handleDateFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value;

    if (!date) {
      getReviews({ userId: id });
      return;
    }
    getReviews({ userId: id, createdAt: date });
  };

  if (error) return <div>{error}</div>;

  return (
    <Box mt={5}>
      <ReviewTable
        ratingColumns={reviews?.[0]?.answers}
        reviews={filteredReviews}
        loading={loading}
      />
    </Box>
  );
};

export default VendorReviewsList;
