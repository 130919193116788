import { PAGE_SIZES } from "../constants";

export default {
  APP: {
    CONTACT_US: "/contact-us",
  },
  COMMON: {
    GET_TIMEZONE_LIST: "/timezone",
    GET_TOPIC_LIST: "/topics",
    GET_OPPORTUNITY_CATEGORIES: "/opportunity/categories",
    GET_INDUSTRIES: "/industries",
    GET_TITLES: "/titles",
    GET_CATEGORIES: "/categories",
    GET_NO_OF_EMP: "/no-of-employees",
    GET_STATES: "/states",
    GET_COUNTRIES_LIST: "https://countriesnow.space/api/v0.1/countries",
    GET_STATE_LIST_FROM_COUNTRY:
      "https://countriesnow.space/api/v0.1/countries/states",
  },
  AUTH: {
    LOGIN: "/auth/login",
    SIGNUP: "/auth/sign-up",
    LINKEDIN: "/auth/linkedin",
    LOGOUT: "",
    VALIDATE_TOKEN: "",
    BECOME_A_HOST: "/host-request",
    BECOME_A_HOST_LINKEDIN: "/host-request/linkedin",
    SIGNUP_OTHER_DETAILS: (userId) => `/users/${userId}`,
  },
  DASHBOARD: {
    GET_EVENTS: ({ startDate, endDate }) =>
      `/ambassador-invitations?from=${startDate}&to=${endDate}`,
    GET_NEW_MEETING_COUNT: ({ date }) =>
      `/dashboard/meeting-count?date=${date}`,
    GET_NEW_AMBASSADOR_COUNT: ({ date }) =>
      `/dashboard/ambassador-count?date=${date}`,
    GET_NEW_CLIENT_COUNT: ({ date }) => `/dashboard/client-count?date=${date}`,
    GET_NEW_REVENUE_COUNT: ({ date }) =>
      `/dashboard/revenue-count?date=${date}`,
  },
  CLIENTS: {
    GET_CLIENTS: ({ selectedPage, selectedPageSize, searchValue = "" }) =>
      `/clients?page=${selectedPage}&limit=${selectedPageSize}&search=${searchValue}`,
    GET_CLIENT_MEETINGS: ({ clientId, selectedPage, selectedPageSize }) =>
      `/ambassador-invitations?clientId=${clientId}&page=${selectedPage}&limit=${selectedPageSize}&withDetails=true`,
    GET_CLIENT_UPCOMING_MEETINGS: ({ clientId, date, token, invitationId }) =>
      `/ambassador-invitations/${token}?clientId=${clientId}&from=${date}&${invitationId}&withDetails=true`,
    GET_CLIENT_INVITATION_STATUS: ({ clientId, selectedDate }) =>
      `/invitation/status?clientId=${clientId}&date=${selectedDate}`,
    GET_CLIENT_PAYMENTS: ({ clientId }) =>
      `/payment/status?clientId=${clientId}`,
    GET_CLIENT_CAMPAIGNS: ({ clientId }) => `/client/${clientId}/campaigns`,
    GET_CLIENT_DETAILS: (id) => `/client/${id}`,
    DELETE_CLIENT: (id) => `/client/${id}`,
    ADD_CLIENT: `/client`,
    UPDATE_CLIENT: (id) => `/client/${id}`,
    GET_AMBASSADOR_BASED_ON_CRITERIA: (id) => `/clients/ambassadors/${id}`,
    SUBMIT_NEW_BUDGET: (id) => `/client/${id}`,
    GET_CLIENT_SELECTED_AMBASSADOR_: (id, selectedOnly) =>
      `/clients/ambassadors/${id}?selectedOnly=${selectedOnly}`,
    GET_MEETING_SUMMARY: (id) => `/client/meeting-data/${id}`,
    GET_COMPANY_NAME_WITHOUT_DELEGATE: ({
      selectedPage,
      selectedPageSize,
      searchValue = "",
    }) =>
      `/clients?page=${selectedPage}&limit=${selectedPageSize}&search=${searchValue}&withDelegate=false`,
  },
  AMBASSADORS: {
    GET_AMBASSADORS: ({ selectedPage, selectedPageSize, searchValue }) =>
      `/ambassadors?page=${selectedPage}&limit=${selectedPageSize}&search=${searchValue}`,
    GET_AMBASSADORS_SIMPLE_LIST: ({
      selectedPage,
      selectedPageSize,
      searchValue,
    }) =>
      `/ambassadors/simple-list?page=${selectedPage}&limit=${selectedPageSize}&search=${searchValue}`,
    GET_AMBASSADOR_DETAILS: (id) => `/ambassador/${id}`,
    DELETE_AMBASSADOR: (id) => `/ambassador/${id}`,
    ADD_AMBASSADOR: `/ambassador`,
    UPDATE_AMBASSADOR: (id) => `/ambassador/${id}`,
    GET_AMBASSADOR_MEETINGS: ({
      selectedPage,
      selectedPageSize,
      ambassadorId,
    }) =>
      `/ambassador-invitations?ambassadorId=${ambassadorId}&page=${selectedPage}&limit=${selectedPageSize}&withDetails=true`,
    GET_AMBASSADOR_PAYMENTS: ({ ambassadorId }) =>
      `/payment/status?ambassadorId=${ambassadorId}`,
    SUBMIT_PHOTO_UPLOAD: (id) => `/ambassador/upload/photo/${id}`,
  },
  CLIENT_AMBASSADOR_INVITE: {
    GET_INVITE_DETAILS: (token, invitationId) =>
      `/invitation/${token}/${invitationId}`,
    SUBMIT_SLOT_SELECTION: (token, invitationId) =>
      `/invitation/${token}/${invitationId}`,
    INVITE_CLIENT_AMBASSADOR: `/invitation`,
    GET_CLIENT_SLOTS_SELECTION: (token, ambassadorInvitationId) =>
      `/ambassador-invitation/${token}/${ambassadorInvitationId}`,
    SUBMIT_AMBASSADOR_SLOT_SELECTION: (token, ambassadorInvitationId) =>
      `/ambassador-invitation/${token}/${ambassadorInvitationId}`,
  },
  INVITATIONS: {
    GET_INVITATION_LIST: (
      selectedPage,
      selectedPageSize,
      searchValue,
      selectedDate,
      status
    ) =>
      `/ambassador-invitations?clientId=${searchValue}&date=${selectedDate}&page=${selectedPage}&limit=${selectedPageSize}&withDetails=true&status=${status}`,
    SUMIT_INVITATION_APPROVAL: (id, type) =>
      `/ambassador-invitation/${id}/${type}`,
    UPDATE_INVITATION: ({ id }) => `/ambassador-invitation/${id}`,
    SUBMIT_MANUAL_MEETING: `/invitation/quick-meeting`,
    ARCHIEVE_MEETING: ({ id }) => `/ambassador-invitation/${id}/archive`,
    POST_TRANSFER_ROLE: `/delegate`,
  },
  CONNECT: {
    GET_AMBASSADOR_CONNECT_REQUEST: `/users?isVerified=false&role=ambassador`,
    GET_CLIENT_CONNECT_REQUEST: `/users?isVerified=false&role=vendor`,
    APPROVE_USER_REQUEST: (id) => `/user/verified/${id}`,
    REJECT_USER_REQUEST: (id) => `/user/reject/${id}`,
  },
  OPPORTUNITY: {
    SUBMIT_EMAIL_TEMPLATE: `/opportunity`,
    GET_OPPORTUNITIES_LIST: ({ selectedPage, selectedPageSize }) =>
      `/opportunities?page=${selectedPage}&limit=${selectedPageSize}`,
    GET_OPPORTUNITY_RESPONSE_LIST: ({ selectedPage, selectedPageSize }) =>
      `/opportunities/insights?page=${selectedPage}&limit=${selectedPageSize}`,
    GET_OPPORTUNITIES_VENDOR_VIEWERS_LIST: (id) =>
      `/opportunities/view-by/${id}`,
    SUBMIT_CONVERT_TO_VENDOR: (id) => `/opportunity/convert-client/${id}`,
  },
  MARKETPLACE: {
    GET_MARKETPLACE_VENDOR_DATA_BY_ID: (id) =>
      `/marketplace/admin-profile/${id}`,
    GET_MARKETPLACE_CATEGORIES_LIST: "/marketplace/categories",
    GET_MARKETPLACE_LIST: ({
      selectedPage = 1,
      selectedPageSize = PAGE_SIZES[0],
      respondedOnly = false,
    }) =>
      `/marketplace?page=${selectedPage}&limit=${selectedPageSize}&respondedOnly=${respondedOnly}`,
    GET_MARKETPLACE_COUNTRIES_LIST: `/marketplace/countries`,
    SEARCH_KEYWORDS_LIST: (searchValue) =>
      `/marketplace/keywords?search=${searchValue}`,
    SUBMIT_SHARE_PROFILE: (id) => `/marketplace/share-profile/${id}`,
    SUBMIT_FORM: `/marketplace/new`,
    UPDATE_FORM: (id) => `/marketplace/update/${id}`,
    UPLOAD_MARKETPLACE_FILE: (field, id) =>
      `/marketplace/admin-upload/file/${field}/${id}`,
  },
  TYPEFORM: {
    GET_REVIEWS: ({ selectedPage = 1, selectedPageSize = PAGE_SIZES[0] }) =>
      `/typeform/answers?page=${selectedPage}&limit=${selectedPageSize}`,
    GET_ALL_REVIEWS: `/typeform/answers`,
    GET_REVIEW_REPORT_DETAILS: `/typeform/report/generate/all`,
    GET_REVIEW_REPORT_DETAILS_BY_CLIENT: (clientId, ambassadorId) =>
      `/typeform/report/generate/byVendor?clientId=${clientId}&ambassadorId=${ambassadorId}`,
    GET_TYPEFORM_AMBASSADOR_COMPANY_NAME: (id) => `/typeform/ambassadors?clientId=${id}`,
  },
};
